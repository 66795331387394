import { ProgrammeProvider } from "@components/__templates__/ProgrammeDetailsPage/ProgrammeDetailsProvider";
import { SettingsProvider } from "@utilities/context/settings";
import formatCoveoMetadata, {
  formatPublicationAuthors,
} from "@utilities/formatCoveoMetadata";
import Link from "@components/Link/Link";
import Layout from "../Layout/Layout";
import Components from "../__Components__/Components";
import { useEffect } from "react";
import setGADataLayer from "@utilities/setGADataLayer";
//temporary until post go live where these should be transfered to a suitable place in Contentful
import GALandingPages from "../../data/GALandingPages.json";
import { useRouter } from "next/router";

export default function Page({ props }) {
  const {
    globalContent,
    pageContent,
    settings,
    programmeData,
    preview,
    canonicalUrl,
  } = props;
  const { programmeTypeCode, programmeTypeGroup } = pageContent;
  const pageTitle = pageContent.basePageTemplate?.fields?.pageTitle;
  const pageDescription = pageContent.basePageTemplate?.fields?.pageDescription;
  const pageSlug = pageContent.slug;
  const router = useRouter();
  const setLandingPageDataLayer = (url?: string) => {
    const programmeNameArr = pageSlug.split("/");
    const nameArr = [...programmeNameArr];
    const programmeName = nameArr.length > 1 ? nameArr.reverse()[0] : "";
    const match = url ? url.replace("/", "") : pageSlug;
    if (GALandingPages.find((source) => source === match)) {
      //add to the dataLayer
      setGADataLayer({
        LandingType: programmeNameArr[0].includes("executive-education")
          ? "EE"
          : "DE",
        ProgrammeName: programmeName,
        event: "LandingPageView",
      });
    }
  };

  useEffect(() => {
    setLandingPageDataLayer();
  }, []);

  useEffect(() => {
    router.events.on("routeChangeComplete", setLandingPageDataLayer);
    return () => {
      router.events.off("routeChangeComplete", setLandingPageDataLayer);
    };
  }, [router.events]);

  function getSubNavLinks(entries) {
    if (entries) {
      let anchorHeadings = [];
      for (let key in entries) {
        if (Array.isArray(entries[key])) {
          anchorHeadings = anchorHeadings.concat(
            entries[key]
              .filter((entry) => {
                if (
                  entry?.sys?.contentType?.sys?.id === "anchorHeading" ||
                  entry?.sys?.contentType?.sys?.id === "tabbedContent"
                ) {
                  return entry.fields;
                }
              })
              .map((entry) => entry.fields)
          );
        } else if (entries[key] && typeof entries[key] === "object") {
          anchorHeadings = anchorHeadings.concat(getSubNavLinks(entries[key]));
        }
      }
      return anchorHeadings;
    }
    return [];
  }

  let coveoMetadata;

  coveoMetadata = {
    templateName: pageContent?.component,
  };

  switch (pageContent?.component) {
    case "publicationDetail":
      coveoMetadata = {
        ...coveoMetadata,
        fields: {
          publicationAuthors: formatPublicationAuthors(
            pageContent?.publicationAuthors
          ),
          publicationSubjectAreasList: formatCoveoMetadata(
            pageContent?.publicationSubjectAreasList
          ),
          publicationYear: pageContent?.publicationYear,
          publicationResearchCenterNameList: formatCoveoMetadata(
            pageContent?.publicationResearchCenterNameList
          ),
        },
      };
      break;
  }

  const allTags = globalContent.tags.map((item) => ({
    name: item.name,
    id: item.sys.id,
  }));

  return (
    <>
      <SettingsProvider
        settings={{
          currentPageContext: `${pageContent.uid}|${pageSlug}|${pageTitle}|${pageDescription}`,
          ...{
            ...settings,
            allTags: allTags,
          },
        }}
      >
        <ProgrammeProvider
          data={{ ...programmeData, programmeTypeCode, programmeTypeGroup }}
        >
          <Layout
            subNavLinks={getSubNavLinks(pageContent)}
            globalContent={globalContent}
            pageMeta={{
              ...pageContent.basePageTemplate?.fields,
              googleSiteVerification:
                settings?.siteSettings?.googleSiteVerification,
            }}
            slug={`${process.env.NEXT_PUBLIC_URL}${canonicalUrl}`}
            title={pageContent.basePageTemplate?.fields?.pageTitle}
            headerImage={
              pageContent?.headerBanner?.fields?.desktopImage?.fields?.image
                ?.fields?.file?.url
            }
            coveoMetadata={coveoMetadata}
            tags={pageContent?.tags || []}
          >
            {Components(pageContent, globalContent, globalContent)}
          </Layout>
        </ProgrammeProvider>
      </SettingsProvider>

      {!!preview && (
        <div className="preview-mode">
          Preview Mode Enabled
          <Link prefetch={false} href="/api/preview/exit">
            Exit Preview
          </Link>
        </div>
      )}
    </>
  );
}
