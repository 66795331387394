import Grid from "@components/Grid/Grid";
import Image from "@components/Image/Image";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { themr } from "@friendsofreactjs/react-css-themr";
import IconLinkedIn from "../../public/assets2019/img/svg/linkedincircle.svg";
import styles from "./C261_Contact.module.scss";

export interface ContactLinkedInProps {
  linkedInLink: string;
  title: string;
  name: string;
  profilePicture: ImageWrapperType;
  theme?: any;
}

export const LinkedInContactDetails = (props: ContactLinkedInProps) => {
  const { linkedInLink, name, title, profilePicture, theme } = props;
  return (
    <Grid column sm={12} md={6} lg={12}>
      <div className={theme["contact-person"]}>
        {linkedInLink ? (
          <a href={linkedInLink} target="_blank" rel="noreferrer">
            <div className={theme["image-wrapper"]}>
              {profilePicture && (
                <Image
                  width={98}
                  height={98}
                  src={profilePicture.fields.image.fields.file.url}
                  alt={profilePicture.fields.altText || ""}
                />
              )}
            </div>
            <div className={theme["contact-info"]}>
              <p className={theme["name"]}>
                <span>{name}</span>
                {linkedInLink.indexOf("linkedin") != -1 && (
                  <i
                    className={theme["icon-linkedincircle"]}
                    aria-label="Linkedin - Opens in a new window"
                    data-grunticon-embed="data-grunticon-embed"
                  >
                    <IconLinkedIn />
                  </i>
                )}
              </p>
              <span className={theme["position"]}>
                <span>{title}</span>
              </span>
            </div>
          </a>
        ) : (
          <>
            <div className={theme["image-wrapper"]}>
              {profilePicture && (
                <Image
                  width={98}
                  height={98}
                  src={profilePicture.fields.image.fields.file.url}
                  alt={profilePicture.fields.altText || ""}
                />
              )}
            </div>
            <div className={theme["contact-info"]}>
              <p className={theme["name"]}>
                <span>{name}</span>
              </p>
              <span className={theme["position"]}>
                <span>{title}</span>
              </span>
            </div>
          </>
        )}
      </div>
    </Grid>
  );
};

export default themr("LinkedInContactDetails", styles)(LinkedInContactDetails);
