import Grid from "@components/Grid/Grid";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useEffect, useState } from "react";
import IconArrowLong from "../../public/image/svg/arrow-long.svg";
import styles from "./C249_FacultyProfileCarousel.module.scss";

import FacultyProfileMulti from "./C249_FacultyProfileMulti";
import FacultyProfileSolo from "./C249_FacultyProfileSolo";

import { MQ_AND_BELOW, MQ_AND_UP } from "@customTypes/Breakpoints";
import clsx from "clsx";

import Swiper, { Pagination } from "swiper";
import { Swiper as SwiperCarousel, SwiperSlide } from "swiper/react";

export interface FacultyProfileCarouselProps {
  facultyProfiles?: any;
}

export const FacultyProfileCarousel = (props: {
  content: FacultyProfileCarouselProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const { facultyProfiles } = content;

  const [swiper, setSwiper] = useState<Swiper>();
  const [slide, setSlide] = useState(1);
  const [totalSlides, setTotalSlides] = useState(1);

  const goToNextSlide = (e) => {
    swiper?.slideNext();
  };

  // isDesktop is used for when you have a grid of 3 items
  const [isDesktop, setIsDesktop] = useState(true);

  // isMobile to identify if we show carousel arrow
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsDesktop(window.innerWidth >= MQ_AND_UP.DESKTOP);
    setIsMobile(window.innerWidth <= MQ_AND_BELOW.MOBILE);

    window.addEventListener("resize", () => {
      setIsDesktop(window.innerWidth >= MQ_AND_UP.DESKTOP);
      setIsMobile(window.innerWidth <= MQ_AND_BELOW.MOBILE);
    });
  }, []);

  let swiperParams = {};

  const sharedParams = {
    pagination: { clickable: false },
    modules: [Pagination],
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 20,
    className: theme.swiper,
    loop: true,
    onSwiper: (swiper) => {
      setSwiper(swiper);
      setTotalSlides(swiper?.pagination?.bullets?.length);
    },
    onSlideChange: () => {
      swiper?.pagination?.bullets.forEach((item, index) => {
        if (item.classList.contains("swiper-pagination-bullet-active")) {
          setSlide(index + 1);
        }
      });
    },
    onBreakpoint: () => {
      setTotalSlides(swiper?.pagination?.bullets?.length || 1);
    },
  };

  if (facultyProfiles.length < 5 && facultyProfiles.length !== 1) {
    swiperParams = sharedParams;
  } else if (facultyProfiles.length > 4) {
    swiperParams = {
      ...sharedParams,
      breakpoints: {
        1024: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 40,
        },
        768: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          spaceBetween: 40,
        },
      },
    };
  }
  return (
    <div
      className={clsx(
        "component",
        theme["faculty-profile-carousel"],
        facultyProfiles.length > 1 && theme["compact"],
        facultyProfiles.length === 3 && theme["two"],
        facultyProfiles.length > 3 && theme["three"]
      )}
    >
      <div className={`${theme.wrapper} wrapper`}>
        {facultyProfiles.length === 1 ? (
          <FacultyProfileSolo
            content={{
              ...facultyProfiles[0]?.fields,
              profilePicture:
                facultyProfiles[0]?.fields?.profilePicture?.fields?.file?.url,
            }}
            theme={theme}
            lightbox={false}
          />
        ) : (
          <>
            {facultyProfiles.length > 4 ||
            (isMobile && facultyProfiles.length > 1) ? (
              <SwiperCarousel {...swiperParams}>
                {facultyProfiles.map((item, index) => {
                  const { name } = item?.fields;

                  return (
                    <SwiperSlide
                      key={`${name}-${index}`}
                      className={theme["swiper-slide"]}
                    >
                      <FacultyProfileMulti
                        content={item?.fields}
                        theme={theme}
                        type={item?.sys?.contentType?.sys?.id}
                      />
                    </SwiperSlide>
                  );
                })}
                {facultyProfiles.length === 3 && isDesktop && <div />}
              </SwiperCarousel>
            ) : (
              <Grid row>
                {facultyProfiles.map((item, index) => {
                  const { name } = item?.fields;

                  return (
                    <Grid
                      column
                      sm={12}
                      md={6}
                      lg={
                        facultyProfiles.length === 3
                          ? 3
                          : 12 / facultyProfiles.length
                      }
                      key={`item-${index}`}
                    >
                      <FacultyProfileMulti
                        content={item?.fields}
                        theme={theme}
                        type={item?.sys?.contentType?.sys?.id}
                      />
                    </Grid>
                  );
                })}
                {facultyProfiles.length === 3 && isDesktop && (
                  <Grid column sm={12} md={6} lg={3}></Grid>
                )}
              </Grid>
            )}
          </>
        )}

        {facultyProfiles.length > 4 && (
          <div
            className={theme.pagination}
            tabIndex={0}
            role="button"
            onClick={goToNextSlide}
          >
            <span className={theme.slide}>
              {String(slide).padStart(2, "0")}
            </span>
            <strong>/</strong>
            <span className="slides">
              {String(totalSlides).padStart(2, "0")}
            </span>
            <i className={theme["icon-arrow-long"]}>
              <IconArrowLong />
            </i>
          </div>
        )}
      </div>
    </div>
  );
};

export default themr("FacultyProfileCarousel", styles)(FacultyProfileCarousel);
