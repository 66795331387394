import Breadcrumb from "@components/BreadcrumbSocialShare/BreadcrumbSocialShareWrapper";
import SiteHeader from "@components/C01_SiteHeader/C01_SiteHeader";
import Footer from "@components/C02_Footer/C02_Footer";
import ProgrammeComparisonOverlay from "@components/C242_ComparisonOverlay/C242_ComparisonOverlay";
import Head from "next/head";
import { Suspense, useContext, useEffect, useState } from "react";
import EMBAGGlobalHeader from "@components/EMBAGGlobalHeader/EMBAGGlobalHeader";
import SubscribeThink from "@components/SubscribeThink/SubscribeThink";
import { Templates } from "@customTypes/Templates";
import { useSettings } from "@utilities/context/settings";
import { SubNavLinkProvider } from "context/subNavLinks";
import Script from "next/script";
import { ModalProvider } from "react-modal-hook";
import { NavigationStateProvider } from "../../context/navigationContext";
import { ProgrammeProvider } from "../../context/programmeComparison";
import { UserContext, UserInfo } from "../../context/user";
import { useRouter } from "next/router";
import setGADataLayer from "@utilities/setGADataLayer";
import { useProgramme } from "@components/__templates__/ProgrammeDetailsPage/ProgrammeDetailsProvider";
import React from "react";
import {
  getGlobalTrackingModel,
  getTrackingModel,
} from "@utilities/trackingModels";

type LayoutProps = {
  globalContent?: any;
  subNavLinks?:
    | {
        referenceTitle?: string;
        pageTitle?: string;
        menuTitle?: string;
        anchorKey: string;
        mainTitle?: string;
        description: string;
        title?: string;
        blueBackground?: boolean;
        tabs?: { [key: string]: any }[];
      }
    | never[];
  slug: string;
  pageMeta?: {
    title: string;
    pageDescription?: string;
    author?: string;
    doNotIndex?: boolean;
    hideFromSearch?: boolean;
    keywords: string[];
    googleSiteVerification?: string;
    image?: {
      fields: {
        file: {
          url: string;
        };
        title: string;
      };
    };
    taxonomyAttributes: string[];
  };
  children: any;
  initialLoggedIn?: boolean;
  title: string;
  headerImage?: string | undefined;
  coveoMetadata?: {
    templateName: string;
    fields: any;
  };
  tags: string[];
};

interface MetaDataProps {
  title: string;
  description: string;
  keywords: string[] | null;
  googleSiteVerification?: string;
  image: string;
  index: "none" | "all" | "noindex";
  follow: "none" | "follow" | "nofollow";
}

export const Layout = ({
  globalContent,
  slug,
  title,
  pageMeta,
  children,
  headerImage,
  coveoMetadata,
  subNavLinks,
  tags,
}: LayoutProps) => {
  const defaultPostfix = "London Business School";
  const metaTitle = pageMeta?.title || title;
  const pageMetaTitle = metaTitle?.includes(defaultPostfix)
    ? metaTitle
    : `${metaTitle} | ${defaultPostfix}`;

  function getTaxonomyCollection(collection) {
    let result: string[] = [];
    collection.forEach((tag) => {
      result.push(tag.name);
    });

    return result;
  }

  const taxonomyCollection = getTaxonomyCollection(tags);
  const metadataObj: MetaDataProps = {
    title: pageMetaTitle,
    description: pageMeta?.pageDescription || "",
    keywords: pageMeta?.keywords || null,
    googleSiteVerification: pageMeta?.googleSiteVerification,
    image:
      headerImage ||
      globalContent?.siteSettings?.defaultSeoImage?.fields?.file?.url,
    index:
      process.env.NEXT_PUBLIC_URL !== "https://www.london.edu"
        ? "none"
        : pageMeta?.doNotIndex === false
        ? "all"
        : "noindex", // only index if the live site and if doNotIndex is not set or set 'no'
    follow:
      process.env.NEXT_PUBLIC_URL !== "https://www.london.edu"
        ? "none"
        : pageMeta?.hideFromSearch === false
        ? "follow"
        : "nofollow",
  };

  const { programmeSettings } = useSettings();
  const router = useRouter();
  const { user } = useContext(UserContext);
  const { programmeTypeCode } = useProgramme();

  let isEMBAG = false;
  if (router?.query?.slug) {
    isEMBAG = router?.query?.slug[0] === "embag";
  }

  const [viewportMeta, setViewportMeta] = useState(
    "initial-scale=1.0, width=device-width"
  );

  const [breadcrumbs, setBreadcrumbs] = useState(globalContent?.breadcrumbs);

  useEffect(() => {
    if (navigator && window) {
      if (
        navigator.userAgent.match(/iPad/i) ||
        (window.outerWidth > 768 && window.outerWidth < 1024)
      ) {
        setViewportMeta("width=1024");
      }
    }
  }, []);

  useEffect(() => {
    const query = router?.query;
    if (query?.sc_camp) {
      sessionStorage.setItem("crm_campaign", `${query.sc_camp}`);
    }
    if (query?.utm_campaign) {
      sessionStorage.setItem("crm_campaign", `${query.utm_campaign}`);
    }
    if (query?.utm_Campaign) {
      sessionStorage.setItem("crm_campaign", `${query.utm_Campaign}`);
    }
    if (query?.utm_event) {
      sessionStorage.setItem("EventCode", `${query.utm_event}`);
    }
  }, [router]);

  const isWindow = typeof window === "object";
  const urlParams = isWindow
    ? new URLSearchParams(window.location.search)
    : null;
  const referPage = urlParams ? urlParams.get("referPage") : "";

  useEffect(() => {
    if (!referPage) {
      setBreadcrumbs(globalContent.breadcrumbs);
      return;
    }

    (async () => {
      const pageUrl = referPage.replace(referPage.charAt(0), "");
      let dynamicBreadcrumbs: any = pageUrl.split("/");
      let url = "";
      dynamicBreadcrumbs = await Promise.all(
        dynamicBreadcrumbs.map(async (crumb, i) => {
          url += i === 0 ? crumb : `/${crumb}`;
          const crumbUrl = url;
          const entry = await (
            await fetch(`/api/getEntryBySlug?slug=${url}`)
          ).json();
          return {
            url: crumbUrl,
            title: entry.title,
          };
        })
      );

      setBreadcrumbs(dynamicBreadcrumbs);
    })();
  }, [router]);

  const [userCopy, setUserCopy] = useState<UserInfo | undefined>();
  useEffect(() => {
    if (JSON.stringify(userCopy) !== JSON.stringify(user) && user !== null) {
      setGADataLayer(getGlobalTrackingModel(user, metadataObj.title));
      setGADataLayer(getTrackingModel(user, programmeTypeCode));
      setUserCopy(user);
    }
  }, [user]);

  const [metObjCopy, setMetObjCopy] = useState<MetaDataProps | undefined>();
  useEffect(() => {
    if (JSON.stringify(metObjCopy) !== JSON.stringify(metadataObj)) {
      setGADataLayer(getGlobalTrackingModel(user, metadataObj.title));
      setGADataLayer(getTrackingModel(user, programmeTypeCode));
      setMetObjCopy(metadataObj);
    }
  }, [metadataObj]);

  return (
    <>
      <Head>
        <title>{metadataObj.title}</title>
        <meta name="description" content={metadataObj.description} />

        <meta charSet="utf-8" />
        <meta name="viewport" content={viewportMeta} />
        <meta name="theme-color" content="#f3f3f3" />
        <link rel="manifest" href="/manifest.json" />
        <link rel="canonical" href={slug} />
        <meta key="twitter:card" name="twitter:card" content="summary" />
        <meta
          key="twitter:site"
          name="twitter:site"
          content={globalContent?.siteSettings?.twitterHandle || "@LBS"}
        />
        <meta
          key="twitter:title"
          name="twitter:title"
          content={metadataObj.title}
        />
        <meta
          key="twitter:description"
          name="twitter:description"
          content={metadataObj.description}
        />
        <meta
          key="twitter:creator"
          name="twitter:creator"
          content={globalContent?.siteSettings?.twitterHandle || "@LBS"}
        />
        <meta
          key="twitter:image"
          name="twitter:image"
          content={`${metadataObj.image}?w=1200&h=1200`}
        />
        <meta key="og:title" property="og:title" content={metadataObj.title} />
        <meta key="og:type" property="og:type" content="article" />
        <meta
          key="og:description"
          property="og:description"
          content={metadataObj.description}
        />
        <meta
          key="og:site_name"
          property="og:site_name"
          content={
            globalContent?.siteSettings?.siteName || "London Business School"
          }
        />
        <meta key="og:locale" property="og:locale" content="en_GB" />
        <meta key="og:url" property="og:url" content={`${slug}`} />
        <meta
          key="og:image"
          property="og:image"
          content={`https:${metadataObj.image}?w=1200&h=600`}
        />

        {metadataObj.keywords && (
          <meta
            key="keywords"
            name="keywords"
            content={metadataObj.keywords.join(", ")}
          />
        )}

        <meta
          name="robots"
          content={[metadataObj.index, metadataObj.follow].join(", ")}
        />

        {metadataObj.googleSiteVerification ? (
          <meta
            name="google-site-verification"
            content={metadataObj.googleSiteVerification}
          />
        ) : null}

        <link
          rel="shortcut icon"
          href="/favicon/favicon.ico"
          type="image/x-icon"
        />
        <link rel="icon" href="/favicon/favicon.ico" type="image/x-icon" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />

        {coveoMetadata?.templateName && (
          <meta
            name="contenttypename"
            content={Templates[coveoMetadata.templateName]}
          />
        )}
        {coveoMetadata?.templateName && (
          <meta name="contenttypeid" content={coveoMetadata.templateName} />
        )}

        <meta name="pagetitle" content={metadataObj.title} />
        <meta name="pagedescription" content={metadataObj.description} />
        {taxonomyCollection.length && (
          <meta name="taxonomytopics" content={taxonomyCollection.join(";")} />
        )}

        {coveoMetadata && coveoMetadata.templateName === "publicationDetail" && (
          <>
            <meta
              name="publicationauthorslist"
              content={coveoMetadata?.fields?.publicationAuthors}
            />
            <meta
              name="publicationsubjectareaslist"
              content={coveoMetadata?.fields?.publicationSubjectAreasList}
            />
            <meta
              name="publicationyear"
              content={coveoMetadata?.fields?.publicationYear}
            />
            <meta
              name="publicationresearchcenternamelist"
              content={coveoMetadata?.fields?.publicationResearchCenterNameList}
            />
          </>
        )}
      </Head>
      <Script id="siteMeta" type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org",
          "@type": "Organization",
          name: globalContent?.siteSettings?.siteName,
          description: globalContent?.siteSettings?.businessDescription,
          logo: globalContent?.siteSettings?.logo?.fields?.file?.url,
          url: process.env.NEXT_PUBLIC_URL,
          telephone: globalContent?.siteSettings?.telephoneNumber,
          sameAs: globalContent?.siteSettings?.referenceWebsites,
          address: {
            "@type": "PostalAddress",
            streetAddress: globalContent?.siteSettings?.address,
            addressLocality: globalContent?.siteSettings?.city,
            postalCode: globalContent?.siteSettings?.postcode,
            addressCountry: {
              "@type": "Country",
              name: globalContent?.siteSettings?.country,
            },
          },
        })}
      </Script>
      <div className="site">
        {globalContent.siteHeader && !isEMBAG && (
          <Suspense fallback={<div>Loading</div>}>
            <NavigationStateProvider>
              <SiteHeader content={globalContent.siteHeader} />
            </NavigationStateProvider>
          </Suspense>
        )}

        {isEMBAG && (
          <Suspense fallback={<div>Loading</div>}>
            <EMBAGGlobalHeader />
          </Suspense>
        )}

        <main>
          {!isEMBAG && (
            <Breadcrumb slug={slug} title={title} breadcrumbs={breadcrumbs} />
          )}
          <SubNavLinkProvider subNavLinks={subNavLinks}>
            <ProgrammeProvider>
              <ModalProvider>{children}</ModalProvider>
              <ProgrammeComparisonOverlay content={programmeSettings} />
            </ProgrammeProvider>
          </SubNavLinkProvider>
          {globalContent?.subscribeThink && (
            <SubscribeThink content={globalContent.subscribeThink} />
          )}
        </main>

        {globalContent?.footer && !isEMBAG && (
          <Suspense fallback={<div>Loading</div>}>
            <Footer content={globalContent.footer} />
          </Suspense>
        )}
      </div>
    </>
  );
};
export default Layout;
