import C282_Electives from "@components/C282_Electives/C282_Electives";
import { Course } from "@components/C282_Electives/ElectiveItem";
import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { fetchApi } from "@utilities/fetchApi";
import { useEffect, useState } from "react";

export interface ProgrammeCoursesProps {
  courseType?: string;
  columns?: string;
  singleDisplay?: boolean;
  defaultOpen?: boolean;
  programme: { fields: ProgrammeDetails };
}

const ProgrammeCourses = (props: { content: ProgrammeCoursesProps }) => {
  const { content } = props;
  const {
    courseType,
    programme: {
      fields: { programmeTitle },
    },
    columns = 4,
  } = content;
  const [courses, setCourses] = useState<Array<Course>>([]);

  useEffect(() => {
    const getData = async () => {
      const data: Course[] = await fetchApi("/api/getProgrammeCourses", {
        programmeTitle,
        courseType,
      });

      setCourses(data);
    };

    getData();
  }, []);

  return <C282_Electives courses={courses} columns={columns} {...content} />;
};

export default ProgrammeCourses;
