import Cta from "@components/Cta/Cta";
import { GeneralLink } from "@customTypes/generalLink";
import { themr } from "@friendsofreactjs/react-css-themr";
import clsx from "clsx";

import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";
import styles from "./ArticleSummary.module.scss";

export interface ArticleSummaryProps {
  articleSummary?: string;
  articleSummaryLabel?: string;
  articleSummaryTinyMce?: TinyMceRichText;
  ctaLink?: GeneralLink;
}

const ArticleSummary = (props: {
  content: ArticleSummaryProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    articleSummary,
    articleSummaryLabel,
    articleSummaryTinyMce,
    ctaLink,
  } = content;

  const Heading = !ctaLink ? "h2" : "h3";

  return (
    <div className={clsx("component", theme.summary)}>
      <div className="wrapper">
        {articleSummaryLabel && <Heading>{articleSummaryLabel}</Heading>}
        <RichTextDisplay
          richText={articleSummary}
          tinyMceRichText={articleSummaryTinyMce}
        />
        {ctaLink && <Cta type="secondary" {...ctaLink.fields} />}
      </div>
    </div>
  );
};

export default themr("ArticleSummary", styles)(ArticleSummary);
