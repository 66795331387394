import { ImageWrapperType } from "@customTypes/imageWrapper";
import { themr } from "@friendsofreactjs/react-css-themr";
import { commaConjunction } from "@utilities/commaConjunction";
import clsx from "clsx";
import { format } from "date-fns";
import Link from "@components/Link/Link";
import { FC } from "react";
import { useSettings } from "@utilities/context/settings";
import EditorsChoiceMedia, {
  EditorsChoiceMediaType,
} from "./EditorsChoiceMedia";

import styles from "./C281_EditorsChoice.module.scss";

export enum TileSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export interface tagData {
  displayNameAndParamValue?: string;
  queryParameter?: string;
  id?: string;
}

export interface EditorsChoiceTileType {
  title: string;
  date: Date;
  linkUrl: string;
  description: string;
  topic?: string;
  image?: ImageWrapperType;
  mediaToRender?: EditorsChoiceMediaType;
  isEvent?: boolean;
  authors?: any[];
  tags?: tagData[];
}

export interface EditorsChoiceTileProps {
  tile: EditorsChoiceTileType;
  size: TileSize;
  theme: any;
}

const EditorsChoiceTile: FC<EditorsChoiceTileProps> = ({
  tile: {
    title,
    linkUrl,
    date,
    description,
    topic,
    mediaToRender,
    isEvent,
    authors,
    tags,
  },
  size,
  theme,
}) => {
  const { siteSettings } = useSettings();

  function getSearchPageSlug(pageCollection) {
    const slug = pageCollection?.find((p) => p.type == "think-articletopics")
      ?.url?.slug;

    return slug;
  }

  return (
    <div className={theme["tile-content"]}>
      {isEvent && (
        <div className="date-indicator">
          <time dateTime={format(date, "yyyy-MM-dd")}>
            <span>{format(date, "MMM")}</span>
            {format(date, "dd")}
          </time>
        </div>
      )}
      <div className={theme["tile-heading-container"]}>
        {size === TileSize.SMALL && mediaToRender && (
          <EditorsChoiceMedia
            mediaToRender={mediaToRender}
            size={TileSize.SMALL}
          />
        )}
        <h2 className={theme["tile-heading"]}>
          <Link href={linkUrl}>
            <a>{title}</a>
          </Link>
        </h2>
      </div>
      <div className="row">
        <time
          className={theme["tile-date"]}
          dateTime={format(date, "yyyy-MM-dd")}
        >
          {format(date, "dd MMMM yyyy")}
        </time>
        {authors && (
          <span className={theme["tile-author"]}>
            By {commaConjunction.format(authors)}
          </span>
        )}
      </div>
      {size === TileSize.LARGE && (
        <p className={theme["tile-description"]}>{description}</p>
      )}
      <div className={clsx("component", "cf", "tag", theme["tag"])}>
        {tags != null &&
          tags.map((tag, index) => (
            <Link
              key={index}
              href={`/${getSearchPageSlug(
                siteSettings?.searchResultsPagesCollection?.items
              )}?${tag.queryParameter}=${tag.displayNameAndParamValue}`}
            >
              <a>{tag.displayNameAndParamValue}</a>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default themr("EditorsChoiceTile", styles)(EditorsChoiceTile);
