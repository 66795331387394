import { useEffect, useState } from "react";
import clsx from "clsx";
import { FormErrors } from "@customTypes/FormErrors";

export interface ProgrammesDataItemType {
  id: string;
  name: string;
  value: string;
}

export interface ProgrammeList {
  [key: string]: ProgrammesDataItemType[];
}

export interface ProgrammesListThemeProps {
  ["brochure-form"]: string;
  component: string;
  ["edit-details"]: string;
  ["field-row"]: string;
  ["fields-floated"]: string;
}

export interface ProgrammesListProps {
  register: any;
  theme: ProgrammesListThemeProps;
  programme: string;
  programmesHeadingText?: string;
  programmesHintText: string;
  programmesSelectedTypes: Array<string>;
  setProgrammesSelectedTypes: Function;
  programmesListData?: ProgrammeList;
  formState: {
    errors: FormErrors;
  };
  name: string;
}

const ProgrammesListSection = (props: ProgrammesListProps) => {
  const {
    register,
    formState,
    theme,
    programme,
    programmesListData,
    programmesHeadingText,
    programmesHintText,
    programmesSelectedTypes,
    setProgrammesSelectedTypes,
    name,
  } = props;

  const [showHint, setShowHint] = useState(true);
  useEffect(() => {
    setProgrammesSelectedTypes(programmesSelectedTypes);
  }, []);

  const handleProgramChange = (event: Event & { target: HTMLInputElement }) => {
    const { value, checked } = event.target;

    if (checked) {
      setProgrammesSelectedTypes([...programmesSelectedTypes, value]);
    } else {
      setProgrammesSelectedTypes(
        programmesSelectedTypes.filter(
          (chosenProgram) => chosenProgram !== value
        )
      );
    }
  };

  useEffect(() => {
    programmesSelectedTypes.length > 0 ? setShowHint(false) : setShowHint(true);
  }, [programmesSelectedTypes]);

  const getValidationError = (value) => {
    if (value?.length === 0) {
      return programmesHintText !== ""
        ? programmesHintText
        : "Programmes of interest is required";
    } else return null;
  };
  return (
    <>
      <h4 className="section-title">{programmesHeadingText}</h4>

      <div
        className={clsx("form-row", theme["form-row"], theme["programmesList"])}
      >
        {programmesListData &&
          programme &&
          programmesListData[programme]?.map((programmeItem) => {
            return (
              <div key={programmeItem.id} className="form-field base6">
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      value={programmeItem.value}
                      checked={programmesSelectedTypes.includes(
                        programmeItem.value
                      )}
                      {...register(name, {
                        validate: getValidationError,
                        onChange: handleProgramChange,
                      })}
                    />

                    <span className="tick"></span>
                    {programmeItem.name}
                  </label>
                </div>
              </div>
            );
          })}

        {showHint && programmesHintText !== "" && (
          <div className="form-field base6">
            <div className="field">
              <input
                id="hiddenPOIChecked"
                name="hiddenPOIChecked"
                type="hidden"
                value=""
              />
            </div>

            <div className="field base6 message-wrapper inline-message field_error_msg">
              <p className="message">{programmesHintText}</p>
            </div>
          </div>
        )}

        {formState.errors && formState.errors[name] && (
          <div className="form-row error">
            <div className="form-field base6">
              <div
                className={clsx(
                  "field",
                  "message-wrapper",
                  "inline-message",
                  formState.errors[name]?.message && "field_error_msg"
                )}
                tabIndex={0}
              >
                <p
                  className={clsx(
                    "message",
                    formState.errors[name]?.message && "active"
                  )}
                >
                  {formState.errors[name]?.message}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default ProgrammesListSection;
