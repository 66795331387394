import { useForm } from "react-hook-form";
import { useEffect, useState, useContext } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import { themr } from "@friendsofreactjs/react-css-themr";

import FormPrivacySection from "@components/FormPrivacySection/FormPrivacySection";
import {
  getPersonalDetails,
  getTitles,
  setProgrammeEnquiry,
  setUserProgrammeEnquiry,
} from "../../services/forms";
import Grid from "@components/Grid/Grid";
import { UserContext } from "../../context/user";
import FormField from "@components/Form/FormFields/FormFields";
import { mapFormData } from "@utilities/mapFormData";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import LinkedIn from "@components/LinkedIn/LinkedIn";
import getCookie from "@utilities/getCookie";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import { LOGIN_URL } from "../../constants";

import styles from "./ProgrammeEnquiry.module.scss";

export interface ProgrammeType {
  programmeTitle: string;
}

export interface ProgrammeEnquiryProps {
  introText: string;
  emailLabel: string;
  firstNameLabel: string;
  lastNameLabel: string;
  companyLabel: string;
  jobLabel: string;
  titleLabel: string;
  passwordLabel: string;
  confirmPasswordLabel: string;
  passwordHintText: string;
  successMessage: {
    fields: {
      content: any;
      contentTinyMce: any;
    };
  };
  failedMessage: {
    fields: {
      content: any;
      contentTinyMce: any;
    };
  };
}

export const ProgrammeEnquiry = (props: {
  content: ProgrammeEnquiryProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    introText,
    emailLabel,
    firstNameLabel,
    lastNameLabel,
    companyLabel,
    jobLabel,
    titleLabel,
    passwordLabel,
    confirmPasswordLabel,
    successMessage,
    failedMessage,
    passwordHintText,
  } = content;

  const [userPersonalDetails, setUserPersonalDetails] = useState<any>(null);
  const [titles, setTitles] = useState<any>(null);
  const [informRadio, setInformRadio] = useState("");
  const [error, setError] = useState<boolean>(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [programme, setProgramme] = useState<ProgrammeType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { user } = useContext(UserContext);
  const { query } = useRouter();
  const { register, handleSubmit, reset, formState, watch } = useForm({
    mode: "onBlur",
  });

  const [loggedInUser, setLoggedInUser] = useState<any>(null);
  const [authToken, setAuthToken] = useState<string>("");
  useEffect(() => {
    user ? setLoggedInUser(true) : setLoggedInUser(null);
    if (user) {
      setAuthToken(getCookie("access_token"));
    }
  }, [user]);

  useEffect(() => {
    if (loggedInUser) {
      const reserveAPlaceFormData = async () => {
        try {
          const personalDetails = await getPersonalDetails(authToken);
          setUserPersonalDetails(personalDetails);
        } catch (e) {
          setError(true);
        }
      };
      reserveAPlaceFormData();
    } else {
      const reserveAPlaceFormData = async () => {
        try {
          const titles = await getTitles();

          setTitles(titles);
        } catch (e) {
          setError(true);
        }
      };

      reserveAPlaceFormData();
    }
  }, [loggedInUser]);

  useEffect(() => {
    if (query.programmeTypeCode && !programme) {
      const getProgramme = async () => {
        const authorData = await fetch(
          `/api/getProgrammeDetailsByCode?typeCode=${
            query.programmeTypeCode || ""
          }`
        );
        setProgramme(await authorData.json());
      };

      getProgramme();
    }
  }, [query]);

  const FormFields = [
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabel,
      dataText: userPersonalDetails?.emailAddress,
      readOnlyOnLoggedIn: true,
    },
    {
      propertyName: "title",
      validation: {
        isRequired: true,
      },
      options: titles,
      formType: "select",
      labelText: titleLabel,
      dataText: userPersonalDetails?.title?.value,
      readOnlyOnLoggedIn: true,
    },

    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabel,
      dataText: userPersonalDetails?.firstName,
      readOnlyOnLoggedIn: true,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: lastNameLabel,
      dataText: userPersonalDetails?.lastName,
      readOnlyOnLoggedIn: true,
    },
    {
      validation: {
        maxLength: 50,
        isRequired: true,
      },
      dataText: userPersonalDetails?.companyName,
      propertyName: "companyName",
      labelText: companyLabel,
    },
    {
      validation: {
        maxLength: 50,
        isRequired: true,
      },
      dataText: userPersonalDetails?.jobTitle,
      propertyName: "jobTitle",
      labelText: jobLabel,
    },
    {
      dataText: "",
      propertyName: "commentOrEnquiry",
      labelText: "Enquiry",
      formType: "textarea",
    },
    {
      propertyName: "password",
      validation: {
        isRequired: true,
        maxLength: 30,
        password: true,
      },
      hintText: passwordHintText,
      labelText: passwordLabel,
      dataText: userPersonalDetails?.password,
      hideOnLoggedIn: true,
    },
    {
      propertyName: "confirmPassword",
      validation: {
        isRequired: true,
        maxLength: 30,
        confirmPassword: true,
      },
      labelText: confirmPasswordLabel,
      hideOnLoggedIn: true,
    },
  ];

  useEffect(() => {
    if (userPersonalDetails) {
      const inputProp = [...FormFields].reduce(
        (o, key) => ({ ...o, [key.propertyName]: key.dataText }),
        {}
      );
      reset(inputProp);
    }
  }, [userPersonalDetails]);

  const onSubmit = async (data) => {
    setLoading(true);
    const CRMIdFields = [...FormFields].reduce(
      (o, key: any) => ({ ...o, [key.propertyName]: key.options }),
      {}
    );

    let transformData: any = mapFormData(data, CRMIdFields);
    transformData.hasAgreedToReceiveEmails = informRadio === "YesToBulkEmail";
    console.log("Form Data ->", transformData);

    try {
      (await loggedInUser)
        ? setUserProgrammeEnquiry(
            transformData,
            sessionStorage.getItem("crm_campaign")
          )
        : setProgrammeEnquiry(
            transformData,
            sessionStorage.getItem("crm_campaign")
          );
      setHasSubmitted(true);
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
    }
  };

  if (hasSubmitted) {
    return (
      <Grid row>
        <Grid column sm={12}>
          <div className="wrapper">
            <RichTextDisplay
              richText={successMessage?.fields?.content}
              tinyMceRichText={successMessage?.fields?.contentTinyMce}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  const FieldJSX = ({ labelText = "", dataText }) => (
    <div className={theme["field-row"]}>
      <label>{labelText}:</label>
      {dataText ? <p>{dataText}</p> : ""}
    </div>
  );

  const mapField = {
    emailAddress: true,
    firstName: true,
    lastName: true,
    title: true,
  };

  if (!programme) {
    return null;
  }

  const formId = programme?.programmeTitle.replace(/\s+/g, "-");

  return (
    <div className={clsx(theme["edit-details"], "wrapper")}>
      <Grid row>
        <Grid column sm={12} md={6}>
          <div className={clsx(theme.component, "component", theme.form)}>
            <h1 className="h2">{programme?.programmeTitle}</h1>
            {error && (
              <FormErrorMessage
                failedRichText={failedMessage?.fields?.content}
                failedTinyMceRichText={failedMessage?.fields?.contentTinyMce}
              />
            )}
          </div>
          <section
            className={clsx(
              theme.component,
              theme["my-profile"],
              theme["form"],
              "form",
              theme.cf
            )}
          >
            <form
              id={formId || "mainform"}
              className="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={theme["fields-floated"]}>
                <>
                  <Grid row>
                    <Grid column sm={12}>
                      <p>{introText}</p>
                      <br />
                      <br />
                      {!loggedInUser && (
                        <p>
                          Already registered?{" "}
                          <a className="underline" href={LOGIN_URL}>
                            Log in here.
                          </a>
                        </p>
                      )}
                    </Grid>
                  </Grid>

                  <LinkedIn formId={formId || "mainform"} />

                  <section className={theme["form-group-wrapper"]}>
                    <div className={theme["form-row"]}></div>
                    {FormFields.map((field, index) =>
                      loggedInUser && mapField[field.propertyName] ? (
                        <FieldJSX
                          key={`${field?.labelText}-${index}`}
                          labelText={field?.labelText}
                          dataText={field?.dataText}
                        />
                      ) : (
                        <>
                          {(!loggedInUser || !field.hideOnLoggedIn) && (
                            <FormField
                              formType={field?.formType}
                              watch={watch}
                              validation={field?.validation}
                              type="text"
                              register={register}
                              property={field.dataText}
                              key={`${field.labelText}${index}`}
                              placeholder={field.labelText}
                              options={field.options}
                              name={field?.propertyName}
                              errors={formState.errors}
                              hintText={field?.hintText}
                              readonly={
                                loggedInUser && field.readOnlyOnLoggedIn
                              }
                            />
                          )}
                        </>
                      )
                    )}
                    {loggedInUser && (
                      <FormPrivacySection
                        register={register}
                        formState={formState}
                      />
                    )}
                  </section>

                  <div className="btn-and-text-wrapper">
                    <SubmitButton
                      loading={loading}
                      id="submitButton"
                      text="Submit"
                    />
                  </div>
                </>
              </div>
            </form>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default themr("ProgrammeEnquiry", styles)(ProgrammeEnquiry);
