import Cta from "@components/Cta/Cta";
import Grid from "@components/Grid/Grid";
import ImageWrapper from "@components/Image/ImageWrapper";
import { GeneralLink } from "@customTypes/generalLink";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";
import { themr } from "@friendsofreactjs/react-css-themr";
import { fetchApi } from "@utilities/fetchApi";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import styles from "./C29_NavigationalEventsListing.module.scss";
import { MQ_AND_BELOW } from "@customTypes/Breakpoints";
import Slider from "react-slick";
import { EventTile } from "@components/C19_20_Tiles/Tiles";
import { EventDetailProps } from "@components/C37.5_EventDetail/C37.5_EventDetail";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";

const DEFAULT_SAMPLE_SIZE = 3;

export interface NavigationalEventsListingProps {
  eventsListingTitle?: string;
  eventsListingImage?: ImageWrapperType;
  description?: any;
  contentTinyMce: TinyMceRichText;
  seeAllEventsLink?: GeneralLink;
  limitEventsShown: number;
  eventForValues: string[];
  eventLocationValues: string[];
  region: string[];
  eventOrganisedByValues: string[];
  topic: string[];
}

export const NavigationalEventsListing = (props: {
  content: NavigationalEventsListingProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    eventsListingTitle,
    eventsListingImage,
    description,
    contentTinyMce,
    seeAllEventsLink,
    limitEventsShown,
    eventForValues,
    eventLocationValues,
    region,
    eventOrganisedByValues,
    topic,
  } = content;
  const [events, setEvents] = useState<EventDetailProps[]>([]);
  const rowTags = [
    ...(eventForValues || []),
    ...(eventLocationValues || []),
    ...(region || []),
    ...(eventOrganisedByValues || []),
    ...(topic || []),
  ];
  const sampleSize = limitEventsShown || DEFAULT_SAMPLE_SIZE;

  useEffect(() => {
    const getData = async () => {
      const eventItems = await fetchApi("/api/getEvents", {
        tags: rowTags.map((tag) => tag.split(":").pop()).join(","),
        limit: sampleSize,
      });

      setEvents(eventItems);
    };
    getData();
  }, []);

  // Carousel
  const [totalDots, setTotalDots] = useState();
  const [slide, setSlide] = useState(1);

  const sliderRef: any = useRef();
  const dotsRef: any = useRef();

  const sliderSettings = {
    slidesToShow: events.length < 3 ? events.length : 3,
    slidesToScroll: events.length < 3 ? events.length : 3,
    className: `${styles.slider} navigational-events-listing-carousel`,
    dots: true,
    speed: 500,
    arrows: false,
    swipeToSlide: true,
    infinite: false,
    responsive: [
      {
        breakpoint: MQ_AND_BELOW.MOBILE,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div className={styles.dots}>
        <ul
          className="slick-dots"
          aria-label="Carousel slide controls"
          ref={dotsRef}
        >
          {dots}
        </ul>
      </div>
    ),
    beforeChange: (current, next) => {
      const totalDots = dotsRef.current.childNodes.length;
      let newSlide;
      if (current !== next) {
        if (current < next) {
          // Slide forward
          newSlide = slide === totalDots ? 1 : slide + 1;
        } else {
          // Slide back
          newSlide = slide === totalDots ? 1 : slide - 1;
        }
        if (next === 0) {
          newSlide = 1;
        }
        setSlide(newSlide);
      }
    },
    onReInit: () => {
      if (dotsRef.current) {
        if (totalDots !== dotsRef.current.childNodes.length) {
          setTotalDots(dotsRef.current.childNodes.length);
          sliderRef?.current?.slickGoTo(0, true);
          setSlide(1);
        }
      }
    },
  };

  return (
    <div
      className={clsx(
        "component",
        theme["navigational-events-listing"],
        theme.blue
      )}
      data-item={sampleSize}
    >
      {eventsListingImage && (
        <div className={clsx(theme["wrapper-wide"], "wrapper-header")}>
          <Grid row>
            <Grid column sm={12}>
              <ImageWrapper
                className={theme["header-image"]}
                image={eventsListingImage}
              />
            </Grid>
          </Grid>
        </div>
      )}
      <div className={clsx(theme["wrapper-wide"], theme["wrapper-heading"])}>
        <div className={clsx(theme.wrapper, "wrapper")}>
          <Grid row>
            <Grid column sm={9}>
              <h2 className={theme.headline}>
                <span>{eventsListingTitle}</span>
              </h2>
              <div className={theme["supporting-copy"]}>
                <RichTextDisplay
                  richText={description}
                  tinyMceRichText={contentTinyMce}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={clsx(theme["wrapper-wide"], theme["wrapper-articles"])}>
        <div className={clsx(theme.wrapper, "wrapper")}>
          <Grid row>
            <Grid column sm={12}>
              <div className={theme["event-wrapper"]}>
                {events.length === 1 ? (
                  <EventTile
                    model={events[0]}
                    withImage={events[0].eventTopImage}
                  />
                ) : (
                  <>
                    {events.length > 1 && (
                      <Slider {...sliderSettings} ref={sliderRef}>
                        {events.map((event) => (
                          <EventTile
                            key={event.eventTitle}
                            model={event}
                            withImage={event.eventTopImage}
                          />
                        ))}
                      </Slider>
                    )}
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={clsx(theme.wrapper, theme["cta-container"], "wrapper")}>
        <Grid row center>
          <Grid column sm={8} md={3}>
            <p>
              {seeAllEventsLink && (
                <Cta type="primary" {...seeAllEventsLink.fields} />
              )}
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default themr(
  "NavigationalEventsListing",
  styles
)(NavigationalEventsListing);
