import React, { useEffect, useRef } from "react";
import IconCross from "../../public/image/svg/cross.svg";
import * as basicLightbox from "basiclightbox";

interface LightboxProps {
  children: React.ReactNode;
  onCloseFunction: Function;
  isBanner?: boolean;
  customClass?: string;
  customInnerClass?: string;
}
// @themr('Lightbox', styles)
const Lightbox = (props: LightboxProps) => {
  const lightboxRef: any = useRef();
  const { isBanner, children, onCloseFunction, customClass, customInnerClass } =
    props;

  useEffect(() => {
    const instance = basicLightbox.create(lightboxRef.current, {
      onClose: () => {
        document.documentElement.style.overflowY = "auto";

        // Bespoke function to fire when lightbox closes
        if (onCloseFunction) {
          onCloseFunction();
        }
      },
      onShow: () => {
        customClass && instance.element().classList.add(customClass);
        customInnerClass &&
          instance
            .element()
            .querySelector(".basicLightbox__placeholder")
            .classList.add(customInnerClass);
        isBanner && instance.element().classList.add("banner");
        isBanner && instance.element().classList.add("basicLightbox--iframe");
        document.documentElement.style.overflowY = "hidden";
        instance.element().querySelector(".close").onclick = instance.close;
      },
    });
    instance.show();
  }, []);

  return (
    <div ref={lightboxRef}>
      <div className={`${isBanner ? "video-wrapper" : ""}`}>{children}</div>
      <span className="close" tabIndex={0} aria-label="close">
        <IconCross />
      </span>
    </div>
  );
};

export default Lightbox;

//export default themr('Lightbox', styles)(Lightbox);
