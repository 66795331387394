import Cta from "@components/Cta/Cta";
import ImageWrapper from "@components/Image/ImageWrapper";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { themr } from "@friendsofreactjs/react-css-themr";
import { commaConjunction } from "@utilities/commaConjunction";
import { useDevice } from "@utilities/react/get-device/get-device";
import clsx from "clsx";
import Link from "@components/Link/Link";
import { Pagination } from "swiper";
import { Swiper as SwiperCarousel, SwiperSlide } from "swiper/react";

import styles from "./C19_PromotionalArticles.module.scss";

export type ArticlePromotionType = {
  slug: string;
  title: string;
  description: string;
  image: ImageWrapperType;
  subtitle?: string;
  authors?: string[];
};

export interface PromotionalArticlesProps {
  articles: ArticlePromotionType[];
  ctaText?: string;
  isBlue?: boolean;
  theme: any;
}

export const PromotionalArticles = (props: PromotionalArticlesProps) => {
  const { articles, isBlue, ctaText, theme } = props;
  const device = useDevice();
  const isDesktop = ["desktop", "desktop-large"].includes(device);

  const buildArticles = (articles: ArticlePromotionType[]) => {
    return articles?.map((article, index) => {
      return isDesktop ? (
        <ArticlePromotion
          key={`${article.title}${index}`}
          theme={theme}
          isBlue={isBlue}
          ctaText={ctaText}
          article={article}
        />
      ) : (
        <SwiperSlide key={`${article.title}${index}`}>
          <ArticlePromotion
            theme={theme}
            isBlue={isBlue}
            ctaText={ctaText}
            article={article}
          />
        </SwiperSlide>
      );
    });
  };

  return (
    <div
      className={clsx(theme["promotional-articles"], {
        [theme["blue"]]: isBlue,
      })}
      data-items={articles?.length || null}
    >
      <div className="wrapper">
        <div className={theme["container"]}>
          {isDesktop ? (
            buildArticles(articles)
          ) : (
            <SwiperCarousel
              className={clsx({ ["on-dark"]: isBlue })}
              allowTouchMove={true}
              spaceBetween={10}
              slidesPerView={"auto"}
              centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
            >
              {buildArticles(articles)}
            </SwiperCarousel>
          )}
        </div>
      </div>
    </div>
  );
};

const ArticlePromotion = (props: {
  article: ArticlePromotionType;
  theme: any;
  ctaText?: string;
  isBlue?: boolean;
}) => {
  const { article, theme, ctaText, isBlue } = props;

  const href = `/${article.slug}`;

  return (
    <article>
      {!!article.image && (
        <Link href={href} passHref>
          <a>
            <ImageWrapper image={article.image} />
          </a>
        </Link>
      )}
      <div className={theme["text"]}>
        <div className={theme["line"]}></div>
        {article.subtitle && (
          <p className={theme["label"]}>{article.subtitle}</p>
        )}
        <h4>
          <Link href={href}>
            <a>{article.title}</a>
          </Link>
        </h4>
        <p>{article.description}</p>
        {article.authors && article.authors.length > 0 && (
          <p>
            By <strong>{commaConjunction.format(article.authors)}</strong>
          </p>
        )}
        {ctaText && (
          <Cta
            linkText={ctaText}
            type="tertiary"
            url={href}
            light={isBlue}
            icon="icon-arrow"
          />
        )}
      </div>
    </article>
  );
};

export default themr("PromotionalArticles", styles)(PromotionalArticles);
