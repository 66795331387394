import { themr } from "@friendsofreactjs/react-css-themr";
import { useInView } from "react-hook-inview";

import styles from "./C249_FacultyProfileCarousel.module.scss";
import clsx from "clsx";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Cta from "../Cta/Cta";
import Image from "@components/Image/NextImage";

import { Nationality } from "@customTypes/Nationality";
import { GeneralLink } from "@customTypes/generalLink";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";

export interface FacultyProfileSoloProps {
  academicFocus?: string;
  associatedPrograms?: string;
  careerHighlight?: string;
  experience?: any;
  experienceTinyMce?: any;
  nationality?: Nationality;
  optionalCtaOne?: GeneralLink;
  optionalCtaTwo?: GeneralLink;
  profileName?: string;
  profilePicture?: string;
  recentClients?: string;
  role?: string;
  url?: string;
  showSubHeading?: boolean;
}

const FacultyProfileSolo = (props: {
  content: FacultyProfileSoloProps;
  theme?: any;
  lightbox?: boolean;
}) => {
  const { content, theme, lightbox } = props;
  const {
    academicFocus,
    associatedPrograms,
    careerHighlight,
    experience,
    experienceTinyMce,
    profilePicture,
    profileName,
    nationality,
    optionalCtaOne,
    optionalCtaTwo,
    recentClients,
    role,
    url,
    showSubHeading = true,
  } = content;
  const [ref, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });

  return (
    <div
      className={clsx(
        "component",
        theme["faculty-profile-carousel"],
        "faculty-profile-carousel",
        lightbox && theme["full-profile-container"]
      )}
    >
      <div className={theme.wrapper}>
        <div className={theme["profile-wrapper"]}>
          <div className={theme["pw-inner-wrapper"]}>
            <div className={theme["image-and-text-wrapper"]}>
              <div className={theme["image-wrapper"]} ref={ref}>
                <div className={theme.image}>
                  {profilePicture && (
                    <Image
                      src={profilePicture}
                      alt={profileName || ""}
                      isCrop
                      cropFocus="top"
                      objectFit="cover"
                      layout="fill"
                    />
                  )}
                </div>
              </div>
              <div className={theme["text-wrapper"]}>
                {profileName && (
                  <h3 className={theme["profile-name"]}>{profileName}</h3>
                )}
                {role && <p className="role">{role}</p>}
                {associatedPrograms && (
                  <>
                    <p
                      className={`${theme["programme-type-label"]} ${theme["sub-heading"]}`}
                    >
                      Associated Programmes
                    </p>
                    <p className={theme["programme-type"]}>
                      {associatedPrograms}
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className={theme["profile-details"]}>
              <div className={theme["button-wrapper"]}>
                {url && (
                  <Cta
                    type="tertiary"
                    url={url}
                    linkText="View full profile"
                    icon="icon-arrow"
                  />
                )}
              </div>
              <PerfectScrollbar className={theme["pd-inner-wrapper"]}>
                <div className={theme["overflow-container"]}>
                  {careerHighlight && (
                    <>
                      {showSubHeading && (
                        <p className={theme["sub-heading"]}>Career highlight</p>
                      )}
                      <p>{careerHighlight}</p>
                      <span className={theme["dividing-line"]}></span>
                    </>
                  )}

                  {nationality && (
                    <>
                      {showSubHeading && (
                        <p className={theme["sub-heading"]}>Nationality</p>
                      )}
                      <p>{nationality}</p>
                    </>
                  )}

                  {academicFocus && (
                    <>
                      {showSubHeading && (
                        <p className={theme["sub-heading"]}>Academic Focus</p>
                      )}
                      <p>{academicFocus}</p>
                    </>
                  )}

                  {recentClients && (
                    <>
                      {showSubHeading && (
                        <p className={theme["sub-heading"]}>Recent Clients</p>
                      )}
                      <p>{recentClients}</p>
                    </>
                  )}

                  {(experience || experienceTinyMce) && (
                    <>
                      {showSubHeading && (
                        <p className={theme["sub-heading"]}>Experience</p>
                      )}
                      <RichTextDisplay
                        richText={experience}
                        tinyMceRichText={experienceTinyMce}
                      />
                    </>
                  )}

                  {optionalCtaOne && (
                    <>
                      <br />
                      <br />
                      <Cta
                        type="tertiary"
                        url={optionalCtaOne?.fields?.url}
                        linkText={optionalCtaOne?.fields?.linkText}
                        openInNewTab={optionalCtaOne?.fields?.openInNewTab}
                        icon="icon-arrow"
                      />
                    </>
                  )}

                  {optionalCtaTwo && (
                    <>
                      <br />
                      <br />
                      <Cta
                        type="tertiary"
                        url={optionalCtaTwo?.fields?.url}
                        linkText={optionalCtaTwo?.fields?.linkText}
                        openInNewTab={optionalCtaTwo?.fields?.openInNewTab}
                        icon="icon-arrow"
                      />
                    </>
                  )}

                  {url && (
                    <div className={theme.bottom}>
                      <Cta
                        type="tertiary"
                        url={url}
                        linkText="View full profile"
                        icon="icon-arrow"
                      />
                    </div>
                  )}
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default themr("FacultyProfileSolo", styles)(FacultyProfileSolo);
