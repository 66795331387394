import ClipLoader from "react-spinners/ClipLoader";
import styles from "./SubmitButton.module.scss";

export interface SubmitButtonProps {
  loading: boolean;
  id: string;
  text: string;
  type?: "regular" | "secondary";
}

const SubmitButton = (props: SubmitButtonProps) => {
  const { loading, id, text, type = "regular" } = props;

  return (
    <button
      type="submit"
      id={id}
      className={`${styles.flex} ${loading ? styles.disabled : ""} cta ${type}`}
      disabled={loading}
    >
      {loading ? (
        <>
          <div className={styles.spinner}>
            <ClipLoader
              color={loading ? "#001e62" : "white"}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
          <span className={styles.text}>{text}</span>
        </>
      ) : (
        text
      )}
    </button>
  );
};

export default SubmitButton;
