import React, { useContext, useEffect } from "react";
import { themr } from "@friendsofreactjs/react-css-themr";
import Link from "@components/Link/Link";
import clsx from "clsx";
import styles from "./C01_SiteHeader.module.scss";
import { NavigationStateContext } from "../../context/navigationContext";
import { GeneralLink } from "@customTypes/generalLink";
import { NavLinkList } from "@customTypes/megaNav";
import IconChevron from "../../public/image/svg/chevron.svg";
import { useDevice } from "@utilities/react/get-device/get-device";
import { useRouter } from "next/router";
import { navigationGAClick } from "@utilities/navigationGAClick";
import { LOGIN_URL, LOGOUT_URL } from "../../constants";
import { UserContext } from "../../context/user";

const MainNav = ({
  megaNav,
  secondaryLinks,
  theme,
}: {
  megaNav: NavLinkList[];
  secondaryLinks: GeneralLink[];
  theme?: any;
}) => {
  const {
    showDesktop,
    showMobile,
    handleEnter,
    handleLeave,
    setShowMobile,
    setMainNavSelected,
    setMainNavSelectedIndex,
    mainNavSelectedIndex,
    setTabSelected,
    handleReset,
  } = useContext(NavigationStateContext);

  const { user } = useContext(UserContext);
  const router = useRouter();
  const device = useDevice();
  const isMobile = device === "mobile";

  useEffect(() => {
    handleReset();
  }, [isMobile]);

  const handleNavigationDesktop = (index: string | number, url: string) => {
    if (showDesktop && mainNavSelectedIndex === index) {
      handleLeave();
    } else {
      handleEnter(index);
    }
    setTabSelected(true);
    router.push(url);
  };

  const handleNavigationMobile = (index: string) => {
    setShowMobile(false);
    setMainNavSelected(true);
    setMainNavSelectedIndex(index);
  };

  return (
    <nav className={clsx(showMobile && theme.open)}>
      <ul>
        {megaNav?.map((linkList, index) => {
          const ownLink = linkList?.fields?.ownLink?.fields;
          return ownLink ? (
            <li
              key={`${ownLink.linkText}-${linkList?.sys?.id}`}
              onMouseEnter={() => {
                handleEnter(index);
              }}
              onMouseLeave={() => handleLeave()}
            >
              <a
                href={ownLink?.url || "#"}
                onClick={(e) => {
                  e.preventDefault();
                  isMobile
                    ? handleNavigationMobile(linkList?.sys?.id)
                    : handleNavigationDesktop(index, ownLink.url);
                  navigationGAClick(e);
                }}
              >
                {ownLink.linkText}
                {isMobile && <IconChevron />}
              </a>
            </li>
          ) : null;
        })}
      </ul>

      <ul className={clsx(theme.secondary, theme.mobile)}>
        {secondaryLinks?.map((item, i) => {
          if (user && item?.fields?.url === "/create-a-profile") return;
          return (
            <li key={`${item}${i}`}>
              <Link href={item?.fields?.url || "#"}>
                <a
                  onClick={(e) => {
                    navigationGAClick(e);
                    handleReset();
                  }}
                >
                  {item?.fields?.linkText}
                </a>
              </Link>
            </li>
          );
        })}
        <li key={"login_link"}>
          <a
            href={user ? LOGOUT_URL : LOGIN_URL}
            onClick={(e) => {
              navigationGAClick(e);
              handleReset();
            }}
          >
            {user ? "Logout" : "Login"}
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default themr("MainNav", styles)(MainNav);
