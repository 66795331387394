import { useState, useContext, useEffect } from "react";
import { themr } from "@friendsofreactjs/react-css-themr";
import clsx from "clsx";
import { useForm } from "react-hook-form";

import Grid from "@components/Grid/Grid";
import FormField from "@components/Form/FormFields/FormFields";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import { EeFormFieldsTypes } from "@customTypes/EeFormFieldsTypes";
import { UserContext } from "../../context/user";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";
import { getCountries, getCountriesIso } from "../../services/forms";
import {
  getPaymentErrors,
  getCountryCode,
  hasCountryMatch,
} from "@utilities/payment";

import styles from "./EePayment.module.scss";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import SelectAutocomplete from "@components/SelectAutocomplete/SelectAutocomplete";
import getCookie from "@utilities/getCookie";

export interface EEFormDetailsProps {
  emailLabelText: string;
  firstNameLabelText: string;
  lastNameLabelText: string;
  invoiceNumberLabelText: string;
  addressLabelText: string;
  amountLabelText: number;
  companyNameLabelText: string;
  cityLabelText: string;
  postcodeLabelText: string;
  countyLabelText: string;
  mobileNumberLabelText: string;
  submitButtonLabelText: string;
  formDescription: string;
  pageHeaderTitle: string;
  amountLabelField: string;
  emailHintText: string;
  invoiceNumberHintText: string;
  studentIdcustomerCodeHintText: string;
  amountHintText: string;
  loggedOutMessage: any;
  loggedOutMessageTinyMce: TinyMceRichText;
}

export interface CountriesIsoProps {
  countryName: string;
  countryAlpha2Code?: string;
}

export const EePayment = (props: {
  content: EEFormDetailsProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    pageHeaderTitle,
    emailLabelText,
    firstNameLabelText,
    lastNameLabelText,
    invoiceNumberLabelText,
    addressLabelText,
    companyNameLabelText,
    cityLabelText,
    postcodeLabelText,
    countyLabelText,
    mobileNumberLabelText,
    submitButtonLabelText,
    formDescription,
    emailHintText,
    invoiceNumberHintText,
    amountHintText,
    amountLabelText,
    loggedOutMessage,
    loggedOutMessageTinyMce,
  } = content;

  const [eeFormDetails, setUserPersonalDetails] =
    useState<EeFormFieldsTypes | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [countries, setCountries] = useState([]);
  const [countriesIso, setCountriesIso] = useState<CountriesIsoProps[]>([]);

  const { user } = useContext(UserContext);

  const fetchCountryData = async (term: string, fieldName: string) => {
    try {
      if (!term) {
        setCountries([]);
        return;
      }
      const countriesResponse = await getCountries(term);
      setCountries(countriesResponse);
    } catch (e) {
      setErrors(["Fetching countries data is failed"]);
      setCountries([]);
    }
  };

  useEffect(() => {
    const getCountryIsos = async () => {
      const countries = await getCountriesIso();
      setCountriesIso(countries);
    };

    getCountryIsos();
  }, []);

  const {
    register,
    handleSubmit,
    formState,
    watch,
    setValue,
    clearErrors,
    trigger,
  } = useForm({
    mode: "onBlur",
  });

  const formFields: any = [
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 255,
      },
      labelText: emailLabelText,
      dataText: eeFormDetails?.emailAddress,
      hintText: emailHintText,
    },
    {
      propertyName: "invoiceNumber",
      validation: {
        isRequired: true,
        maxLength: 30,
        alphaNumeric: true,
      },
      labelText: invoiceNumberLabelText,
      dataText: eeFormDetails?.invoiceNumber,
      hintText: invoiceNumberHintText,
    },
    {
      propertyName: "payAmount",
      validation: {
        isRequired: true,
        range: { min: 10, max: 120000 },
        decimalValidator: true,
      },
      labelText: amountLabelText,
      dataText: eeFormDetails?.amount,
      hintText: amountHintText,
    },
    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
        alphaNumeric: true,
      },
      labelText: firstNameLabelText,
      dataText: eeFormDetails?.firstName,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: lastNameLabelText,
      dataText: eeFormDetails?.lastName,
    },
    {
      propertyName: "companyName",
      validation: {
        maxLength: 30,
      },
      labelText: companyNameLabelText,
      dataText: eeFormDetails?.companyName,
    },
    {
      propertyName: "addressLine1",
      validation: {
        isRequired: true,
        maxLength: 30,
      },
      labelText: addressLabelText,
      dataText: eeFormDetails?.address,
    },
    {
      propertyName: "city",
      validation: {
        isRequired: true,
        maxLength: 30,
        alphaNumeric: true,
      },
      labelText: cityLabelText,
      dataText: eeFormDetails?.city,
    },
    {
      propertyName: "country",
      fieldType: "selectAutocomplete",
      validation: {
        required: "Country of residence is required",
        maxLength: 30,
        validate: (value) =>
          !!hasCountryMatch(value, countries) || "Unknown country entered",
      },
      labelText: countyLabelText,
      dataText: eeFormDetails?.county,
    },
    {
      propertyName: "postCode",
      validation: {
        isRequired: true,
        maxLength: 30,
      },
      labelText: postcodeLabelText,
      dataText: eeFormDetails?.postcode,
    },
    {
      propertyName: "phoneNumber",
      validation: {
        isRequired: true,
        maxLength: 30,
        telephone: true,
      },
      labelText: mobileNumberLabelText,
      dataText: eeFormDetails?.mobileNumber,
    },
  ];
  const parseJwt = (token) => {
    return JSON.parse(Buffer.from(token.split(".")[1], "base64").toString());
  };

  const getStudentID = () => {
    const idToken = getCookie("id_token");
    const decoded = parseJwt(idToken);
    const studentIdFromJWT = decoded["lbs_no"];
    return studentIdFromJWT || "";
  };

  const onSubmit = async (formData) => {
    setErrors([]);
    setLoading(true);

    const data = {
      ...formData,
      PayReference: getStudentID(),
      currencyCode: "GBP",
      country: getCountryCode(formData.country, countriesIso),
      internalReference: "EE",
    };

    localStorage.setItem("paymentFormData", JSON.stringify(data));

    const response = await fetch("/api/postPayment", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    const postPaymentResponseData = await response.json();

    setLoading(false);

    if (!response.ok) {
      setErrors(getPaymentErrors(postPaymentResponseData?.response));
      window.scrollTo(0, 0);
    }

    if (response.ok)
      window.location.href = postPaymentResponseData?.response?.checkOutUrl;
  };

  return (
    <div className={clsx(theme["decc-form"], "wrapper")}>
      <Grid row>
        <Grid column sm={12} md={6}>
          <div className={clsx(theme.component, "component", theme.form)}>
            <h1 className="h2">{pageHeaderTitle}</h1>
            {!user && (
              <RichTextDisplay
                richText={loggedOutMessage}
                tinyMceRichText={loggedOutMessageTinyMce}
              />
            )}
            {user && <p>{formDescription}</p>}
          </div>
          {user && (
            <section
              className={clsx(theme.component, theme["form"], "form", theme.cf)}
            >
              {errors &&
                errors.map((error, index) => (
                  <FormErrorMessage key={index} text={error} />
                ))}
              <form className="form" onSubmit={handleSubmit(onSubmit)}>
                <section className={theme["form-group-wrapper"]}>
                  <div className={theme["form-row"]} />
                  {formFields.map((field, index) => {
                    if (field.fieldType === "selectAutocomplete") {
                      return (
                        <SelectAutocomplete
                          key={`${field.labelText}${index}`}
                          placeholder={field.labelText}
                          name={field.propertyName}
                          register={register}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          errors={formState.errors}
                          value={watch(field.propertyName)}
                          onBlur={() => {
                            trigger(field.propertyName);
                          }}
                          getOptions={(term) =>
                            fetchCountryData(term, field.propertyName)
                          }
                          options={countries}
                          validation={field.validation}
                        />
                      );
                    }

                    return (
                      <FormField
                        key={index}
                        formType={field?.formType}
                        watch={watch}
                        validation={field?.validation}
                        type={field.type === "select" ? "select" : "text"}
                        register={register}
                        property={field.dataText}
                        hintText={field?.hintText}
                        placeholder={field.labelText}
                        options={field.options}
                        optionalText={field?.optionalText}
                        name={field?.propertyName}
                        errors={formState.errors}
                      />
                    );
                  })}
                </section>
                <div className="btn-and-text-wrapper">
                  <SubmitButton
                    loading={loading}
                    id="submitButton"
                    text={submitButtonLabelText || "Submit"}
                  />
                </div>
              </form>
            </section>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default themr("EePayment", styles)(EePayment);
