/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./C72.1_ProgrammeDetailsBar.module.scss";
import Cta from "@components/Cta/Cta";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useDevice } from "@utilities/react/get-device/get-device";
import Slider from "react-slick";
import Grid from "@components/Grid/Grid";
import { fetchApi } from "@utilities/fetchApi";
import { ProgrammeImportData } from "@customTypes/programmeImportData";
import { ProgrammeDetailsBarSettings } from "@customTypes/programmeDetailsBarSettings";
import clsx from "clsx";
import { GeneralLink } from "@customTypes/generalLink";
import { useProgramme } from "@components/__templates__/ProgrammeDetailsPage/ProgrammeDetailsProvider";
import { useSettings } from "@utilities/context/settings";
import { useProgrammeDetailsPage } from "../../context/programmeDetailsPage";
import { ProgrammeCTAType } from "@customTypes/ProgrammeCTAType";
import { UserContext } from "../../context/user";
import DownloadTypes from "../../types/DownloadTypes";
import { useRouter } from "next/router";

export interface ProgrammeDetailsBarProps {
  content: {
    fields: {
      tertiaryCtaLink: GeneralLink;
      ctaLink1: GeneralLink;
      ctaLink2: GeneralLink;
      showDownloadCta: any;
      showApplyNowCta: any;
      helpText: string;
      isSecondVariation: boolean;
      programmeEnrichmentData: any;
    };
  };
  theme?: any;
  isSticky?: boolean;
}

const ProgrammeDetailsBar = (props: ProgrammeDetailsBarProps) => {
  const { content, theme, isSticky } = props;

  const { importDataProgrammeTypeCode } = useProgramme();
  const { siteSettings } = useSettings();
  const { programmeApplyOnlineParameterValue, type } =
    useProgrammeDetailsPage();

  const apply = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.Apply
  )[0];
  const { programmeTypeCode, programmeTypeGroup } = useProgramme();
  const downloadBrochureSlug =
    siteSettings?.programmeCtaPagesCollection?.items.find(
      (item) =>
        item.type.toLowerCase() ===
        DownloadTypes.DOWNLOAD_BROCHURE.toLowerCase()
    );

  if (!content.fields) return;

  const device = useDevice();

  const {
    fields: {
      tertiaryCtaLink,
      ctaLink1,
      ctaLink2,
      showDownloadCta,
      showApplyNowCta,
      helpText,
      isSecondVariation,
      programmeEnrichmentData,
    },
  } = content;

  const mapHybridOfferings = () => {
    if (isSecondVariation) {
      return programmeEnrichmentData?.map((overrideData) => {
        let date = overrideData?.fields?.progDetailBarDate.split(";");
        let location = overrideData?.fields?.progDetailBarLocation.split(";");
        let duration = overrideData?.fields?.progDetailBarDuration.split(";");
        let availability = overrideData?.fields?.programmeAvailability;
        let arr: [{}] = [{}];
        arr.shift();
        for (let i = 0; i < date.length; i++) {
          arr.push({
            ProgrammeInclusiveDates: "Module " + (i + 1) + ": " + date[i],
            ProgrammeDuration: duration[i],
            ProgrammeLocation: location[i],
            programmeAvailabilityString: availability ? availability[i] : "",
          });
        }
        return arr;
      });
    }
  };

  const programmeHybridOfferings = mapHybridOfferings();

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div key={`${i}-custom-paging`} className={`${theme["tns-nav"]}`}>
        <span></span>
      </div>
    ),
  };

  const [programmeImportData, setProgrammeImportData] = useState<
    ProgrammeImportData[]
  >([]);

  const [programmeDetailsBarSettings, setProgrammeDetailsBarSettings] =
    useState<ProgrammeDetailsBarSettings>();

  useEffect(() => {
    const getData = async () => {
      const programmeBarData = await fetchApi(
        "/api/getProgrammeDetailsBarData",
        {
          typeCodes: importDataProgrammeTypeCode,
        }
      );

      setProgrammeImportData(programmeBarData.programmeImportDataCollection);
      setProgrammeDetailsBarSettings(
        programmeBarData.programmeDetailsBarSettings
      );
    };
    getData();
  }, [importDataProgrammeTypeCode]);

  const checkIfSameLocationAndDuration = () => {
    let count = 0;
    programmeImportData.forEach((item) => {
      if (
        item.programmeDuration === programmeImportData[0].programmeDuration &&
        item.programmeLocation === programmeImportData[0].programmeLocation
      ) {
        count++;
      }
    });
    return count === programmeImportData.length;
  };

  const isSameLocationAndDuration = checkIfSameLocationAndDuration();

  const detailsBarRef: any = useRef();
  const optionWrapperRef: any = useRef();
  const optionsRef: any = useRef();

  const [showOptions, setShowOptions] = useState(false);
  const [showSessions, setShowSessions] = useState(false);
  const { user } = useContext(UserContext);
  const router = useRouter();

  const [referPage, setReferPage] = useState("");
  useEffect(() => {
    setReferPage(router.asPath);
  }, [router]);

  useEffect(() => {
    if (isSticky) {
      setShowOptions(false);
      setShowSessions(false);
    }
  }, [isSticky]);

  useEffect(() => {
    if (isSticky && !showSessions) {
      setShowOptions(false);
    }
  }, [isSticky, showOptions, showSessions]);

  useEffect(() => {
    if (optionsRef.current) {
      if (showOptions) {
        let height = optionWrapperRef?.current?.offsetHeight;
        if (height > 400) {
          height = 400;
        }
        optionsRef.current.style.height = height + "px";
      } else {
        optionsRef.current.style.height = "0px";
      }
    }
  }, [showOptions]);

  const onSelectContainerClick = () => {
    if (programmeImportData.length <= 1) {
      return;
    }
    setShowOptions(!showOptions);
  };

  const onSelectContainerKeyPress = (e) => {
    if (programmeImportData.length <= 1) {
      return;
    }
    if ((e.keyCode = 13)) {
      setShowOptions(!showOptions);
    }
  };

  const onToggleSessionsClick = (e) => {
    e.preventDefault();
    setShowSessions(!showSessions);

    setTimeout(() => {
      setShowOptions(!showOptions);
    }, 100);
  };

  const onToggleMoreSessionsClick = (e) => {
    e.preventDefault();

    setShowOptions(!showOptions);
  };

  const onMobileTogglerClick = (e) => {
    e.preventDefault();
    setShowSessions(!showSessions);
  };

  const optionsVisibility = () => {
    if (isSticky) {
      if (showSessions) {
        return showSessions;
      }
      return false;
    }
    return true;
  };

  const hybridDesktopJSX = (index) => (
    <div className={clsx(theme["data-second-variant"], theme["desktop-only"])}>
      {programmeHybridOfferings.slice(1)[index]?.map((offering, i) => (
        <div
          key={`${offering.ProgrammeInclusiveDates}${offering.ProgrammeDuration}${i}`}
        >
          <p>
            <span className={theme["title"]}>Dates</span>
            <span className={theme["value"]}>
              {offering.ProgrammeInclusiveDates}
            </span>
          </p>
          <p>
            <span className={theme["title"]}>Duration</span>
            <span className={theme["value"]}>{offering.ProgrammeDuration}</span>
          </p>
          <p>
            <span className={theme["title"]}>Format</span>
            <span className={theme["value"]}>{offering.ProgrammeLocation}</span>
          </p>
        </div>
      ))}
      <div>
        <p className={theme["smallText"]}>
          {programmeDetailsBarSettings?.allModuleSameCourseLabelEe
            ? programmeDetailsBarSettings.allModuleSameCourseLabelEe.replace(
                "?",
                programmeHybridOfferings.slice(1)[index]?.length.toString()
              )
            : ""}
        </p>
      </div>
    </div>
  );

  const hybridMobileJSX = (i) => (
    <div className={clsx(theme["data-second-variant"], theme.mobile)}>
      {programmeHybridOfferings[i]?.map((offering) => (
        <>
          <p className={theme["module-paragraph"]}>
            {offering.ProgrammeInclusiveDates}
          </p>
          <p className={theme["inner-border"]}>
            <span className={theme.title}>Duration</span>
            {offering.ProgrammeDuration}
          </p>
          <p className={theme["inner-border"]}>
            <span className={theme.title}>Format</span>
            {offering.ProgrammeLocation}
          </p>
        </>
      ))}

      <div>
        <p className={theme.smallText}>
          {programmeDetailsBarSettings?.allModuleSameCourseLabelEe
            ? programmeDetailsBarSettings.allModuleSameCourseLabelEe.replace(
                "?",
                programmeHybridOfferings?.slice(1)[i]?.length.toString()
              )
            : ""}
        </p>
      </div>
    </div>
  );

  const programmeDataLookUp = isSecondVariation
    ? device === "mobile"
      ? programmeHybridOfferings
      : programmeHybridOfferings.slice(1)
    : device === "mobile"
    ? programmeImportData
    : programmeImportData.slice(1);

  return programmeImportData.length ? (
    <div
      ref={detailsBarRef}
      className={clsx(
        theme.component,
        theme["programme-details-bar"],
        theme.two,
        (showSessions || showOptions) && theme.show,
        programmeImportData.length <= 1 && theme["single-option"],
        isSticky && device === "mobile" && theme["sticky-mobile"]
      )}
    >
      <div
        className={clsx(
          theme.wrapper,
          isSticky && device !== "mobile" && theme.sticky,
          isSticky && device === "mobile" && theme["sticky-mobile"],
          "wrapper"
        )}
      >
        <div
          onClick={onToggleSessionsClick}
          className={clsx(
            theme["toggle_available_sessions"],
            showSessions && theme.sessionsOpen
          )}
          id="toggle_available_sessions"
        >
          <a
            className={theme["toggle-link-for-available-sessions"]}
            href=""
            aria-expanded={showSessions ? "true" : "false"}
          >
            {programmeDetailsBarSettings?.stickyToggleTextEe}
          </a>
        </div>
        {optionsVisibility() && (
          <div className={theme["row-middle"]}>
            <Grid row>
              <Grid column lg={9} sm={12}>
                <div className={theme["select-container"]}>
                  <div className={theme.headers}>
                    <p className="">Dates</p>
                    <p className="">Duration</p>
                    <p className="">Format</p>
                  </div>
                  <div
                    onClick={onSelectContainerClick}
                    onKeyDown={onSelectContainerKeyPress}
                    className={theme.select}
                    tabIndex={0}
                    role="combobox"
                    aria-expanded={showOptions ? "true" : "false"}
                    aria-owns="details-select-box"
                    aria-haspopup="listbox"
                  >
                    {programmeImportData && (
                      <>
                        {isSecondVariation ? (
                          <div
                            id="selection"
                            className={theme["selection-second-variant"]}
                            tabIndex={-1}
                          >
                            {programmeHybridOfferings[0]?.map(
                              (programmeData, i) => (
                                <div
                                  key={`${programmeData.ProgrammeInclusiveDates}${programmeData.ProgrammeDuration}${i}`}
                                >
                                  <p>{programmeData.ProgrammeInclusiveDates}</p>
                                  <p>{programmeData.ProgrammeDuration}</p>
                                  <p>{programmeData.ProgrammeLocation}</p>
                                </div>
                              )
                            )}
                            <p className={theme.smallText}>
                              {programmeDetailsBarSettings?.allModuleSameCourseLabelEe
                                ? programmeDetailsBarSettings.allModuleSameCourseLabelEe.replace(
                                    "?",
                                    programmeHybridOfferings[0]?.length.toString()
                                  )
                                : ""}
                            </p>
                          </div>
                        ) : (
                          <div>
                            <div
                              id="selection"
                              className={theme["selection"]}
                              tabIndex={-1}
                            >
                              <p className={theme["hybrid"]}>
                                {programmeImportData[0] &&
                                  programmeImportData[0]
                                    .programmeInclusiveDates}
                              </p>
                              <p className={theme["hybrid"]}>
                                {programmeImportData[0]?.programmeDuration}
                              </p>
                              <p className={theme["hybrid"]}>
                                {programmeImportData[0]?.programmeLocation}
                              </p>
                              <p></p>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  {programmeImportData && programmeImportData.length > 1 && (
                    <div className={theme.accordioneed}>
                      <a
                        onClick={onToggleMoreSessionsClick}
                        id="toggle_more_sessions"
                        href=""
                        className={theme["toggle-link"]}
                        aria-expanded={showOptions ? "true" : "false"}
                      >
                        {!showOptions
                          ? isSameLocationAndDuration
                            ? programmeDetailsBarSettings?.toggleLinkTextPlusEe
                            : programmeDetailsBarSettings?.longToggleLinkTextPlusEe
                          : isSameLocationAndDuration
                          ? programmeDetailsBarSettings?.toggleLinkTextMinusEe
                          : programmeDetailsBarSettings?.longToggleLinkTextMinusEe}
                        <p className="toggle-link-plus-text">
                          {isSameLocationAndDuration
                            ? programmeDetailsBarSettings?.toggleLinkTextPlusEe
                            : programmeDetailsBarSettings?.longToggleLinkTextPlusEe}
                        </p>
                        <p className="toggle-link-minus-text">
                          {isSameLocationAndDuration
                            ? programmeDetailsBarSettings?.toggleLinkTextMinusEe
                            : programmeDetailsBarSettings?.longToggleLinkTextMinusEe}
                        </p>
                        <p className="sticky-toggle-text">
                          {programmeDetailsBarSettings?.stickyToggleTextEe}
                        </p>
                      </a>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid column lg={3} sm={12}>
                <div className={theme.buttons}>
                  {tertiaryCtaLink && (
                    <Cta
                      linkText={tertiaryCtaLink?.fields?.linkText}
                      type="tertiary"
                      url={tertiaryCtaLink?.fields?.url}
                      icon="icon-arrow"
                      light
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <div
        onClick={onMobileTogglerClick}
        className={clsx(
          theme["mobile-toggler"],
          isSticky && device === "mobile" && theme["sticky-mobile"]
        )}
      >
        {programmeDetailsBarSettings?.stickyToggleTextEe}
        <div className={clsx(theme.tick, theme["icon-chevron"])}>
          <svg
            height="20"
            width="20"
            viewBox="0 0 8 12"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            focusable="false"
          >
            <path d="M2 0L.59 1.41 5.17 6 .59 10.59 2 12l6-6z"></path>
          </svg>
        </div>
      </div>
      <div
        className={clsx(
          theme.options,
          isSticky && device !== theme.mobile && theme.sticky,
          !optionsVisibility() && theme.hide
        )}
        ref={optionsRef}
      >
        <div ref={optionWrapperRef} className={clsx(theme.wrapper, "wrapper")}>
          <div className={theme.row}>
            <Grid row>
              <Grid column sm={12} md={12}>
                <div
                  className={theme.container}
                  role="listbox"
                  id="details-select-box"
                >
                  {device === "mobile" ? (
                    <>
                      <Slider {...sliderSettings}>
                        {programmeImportData &&
                          programmeDataLookUp.map((item, i) => (
                            <div
                              key={`${JSON.stringify(item)}${i}`}
                              className={theme.option}
                              data-href={`?id=${i}`}
                              tabIndex={0}
                            >
                              <Grid row>
                                <Grid column md={9} sm={12}>
                                  <div className={theme["option-inner"]}>
                                    {isSecondVariation ? (
                                      hybridMobileJSX(i)
                                    ) : (
                                      <div
                                        className={clsx(
                                          theme.data,
                                          theme.mobile
                                        )}
                                      >
                                        <p>
                                          <span className={theme.title}>
                                            Dates
                                          </span>
                                          <span
                                            className={theme["value-mobile"]}
                                          >
                                            {item.programmeInclusiveDates}
                                          </span>
                                        </p>
                                        <p className={theme["inner-border"]}>
                                          <span className={theme.title}>
                                            Duration
                                          </span>
                                          <span
                                            className={theme["value-mobile"]}
                                          >
                                            {item.programmeDuration}
                                          </span>
                                        </p>
                                        <p className={theme["inner-border"]}>
                                          <span className={theme.title}>
                                            Format
                                          </span>
                                          <span
                                            className={theme["value-mobile"]}
                                          >
                                            {item.programmeLocation}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                    <div className={theme.buttons}>
                                      {showApplyNowCta ? (
                                        <Cta
                                          linkText="Apply now"
                                          type="secondary"
                                          url={`${apply?.url.slug}?applyID=${programmeApplyOnlineParameterValue}`}
                                          light
                                        />
                                      ) : (
                                        <>
                                          {ctaLink1 && (
                                            <Cta
                                              {...ctaLink1.fields}
                                              type="primary"
                                              light
                                            />
                                          )}
                                        </>
                                      )}
                                      {showDownloadCta ? (
                                        <Cta
                                          linkText="Download brochure"
                                          type="tertiary"
                                          url={`${downloadBrochureSlug?.url.slug}?programmeTypeCode=${programmeTypeCode}&programmeTypeGroup=${programmeTypeGroup}&programType=${type}&brochureType=Download a brochure&referPage=${referPage}`}
                                          light
                                          icon="icon-download"
                                        />
                                      ) : (
                                        <>
                                          {ctaLink2 && (
                                            <Cta
                                              {...ctaLink2.fields}
                                              type="tertiary"
                                              light
                                              icon="icon-arrow"
                                            />
                                          )}
                                        </>
                                      )}

                                      {helpText && (
                                        <Cta
                                          url=""
                                          linkText=" "
                                          type="tertiary"
                                          helpText={helpText}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          ))}
                      </Slider>
                    </>
                  ) : (
                    <>
                      {programmeDataLookUp &&
                        programmeDataLookUp.map((item, index) => (
                          <div
                            key={`${JSON.stringify(item)}${index}`}
                            className={clsx(
                              theme.option,
                              index === programmeDataLookUp.length - 1 &&
                                theme["last-option"],
                              !isSameLocationAndDuration && theme.notsame
                            )}
                            data-href={`?id=${index}`}
                            tabIndex={0}
                          >
                            <Grid row>
                              <Grid column md={10} sm={12}>
                                <div className={theme["option-inner"]}>
                                  {isSecondVariation ? (
                                    hybridDesktopJSX(index)
                                  ) : (
                                    <div className={theme["data"]}>
                                      <p className={theme.hybrid}>
                                        <span className={theme["title"]}>
                                          Dates
                                        </span>
                                        <span className={theme["value"]}>
                                          {item.programmeInclusiveDates}
                                        </span>
                                      </p>
                                      {(isSameLocationAndDuration &&
                                        index === 0) ||
                                        (!isSameLocationAndDuration && (
                                          <>
                                            <p className={theme.hybrid}>
                                              <span className={theme["title"]}>
                                                Duration
                                              </span>
                                              <span className={theme["value"]}>
                                                {item.programmeDuration}
                                              </span>
                                            </p>
                                            <p className={theme.hybrid}>
                                              <span className={theme["title"]}>
                                                {isSameLocationAndDuration
                                                  ? "Location"
                                                  : "Format"}
                                              </span>
                                              <span className={theme["value"]}>
                                                {item.programmeLocation}
                                              </span>
                                            </p>
                                          </>
                                        ))}
                                    </div>
                                  )}
                                  <>
                                    {item.programmeAvailabilityString && (
                                      <div className={theme.availability}>
                                        <p>
                                          {item.programmeAvailabilityString}
                                        </p>
                                      </div>
                                    )}
                                    <div className={theme.buttons}>
                                      {showApplyNowCta ? (
                                        <Cta
                                          linkText="Apply now"
                                          type="secondary"
                                          url={
                                            user
                                              ? `/interact?applyID=${programmeApplyOnlineParameterValue}`
                                              : `${apply?.url.slug}?applyID=${programmeApplyOnlineParameterValue}`
                                          }
                                          light
                                        />
                                      ) : (
                                        <>
                                          {ctaLink1 && (
                                            <Cta
                                              {...ctaLink1.fields}
                                              type="primary"
                                              light
                                            />
                                          )}
                                        </>
                                      )}
                                      {false && showDownloadCta ? (
                                        //  && showDownloadCta to be added
                                        //Work related to this will be covered in another ticket
                                        <Cta
                                          linkText="Download brochure"
                                          type="tertiary"
                                          //  showDownloadCta to be decided on the integration
                                          url={"#ApplyLink"}
                                          light
                                          icon="icon-download"
                                        />
                                      ) : (
                                        <>
                                          {ctaLink2 && (
                                            <Cta
                                              {...ctaLink2.fields}
                                              type="tertiary"
                                              light
                                              icon="icon-arrow"
                                            />
                                          )}
                                        </>
                                      )}

                                      {helpText && (
                                        <Cta
                                          url=""
                                          linkText=" "
                                          type="tertiary"
                                          helpText={helpText}
                                        />
                                      )}
                                    </div>
                                  </>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        ))}
                    </>
                  )}
                </div>
                <div className={theme["link-switch"]}>
                  {tertiaryCtaLink && (
                    <Cta
                      linkText={tertiaryCtaLink?.fields?.linkText}
                      type="tertiary"
                      url={tertiaryCtaLink?.fields?.url}
                      icon="icon-arrow"
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default themr("ProgrammeDetailsBar", styles)(ProgrammeDetailsBar);
