import { themr } from "@friendsofreactjs/react-css-themr";
import { UserContext } from "../../context/user";
import { useContext, useEffect, useState } from "react";
import { getSavedPages, savePage } from "../../services/forms";
import ApiError from "@components/GenericApiError/Error";
import styles from "./C30_SaveToMyProfile.module.scss";
import { SettingsContext } from "@utilities/context/settings";
import { useSettings } from "@utilities/context/settings";
import getCookie from "@utilities/getCookie";

export interface SaveToMyProfileProps {
  saveToMyProfileText?: string;
  alreadySavedToMyProfileText?: string;
  loggedOutTitleText?: string;
  theme?: any;
}

export const SaveToMyProfile = ({
  saveToMyProfileText = "Save to my profile",
  alreadySavedToMyProfileText = "Content already saved",
  loggedOutTitleText = "You must log in before saving",
  theme,
}: SaveToMyProfileProps) => {
  const { currentPageContext } = useContext(SettingsContext);
  const { user } = useContext(UserContext);
  const [isPageSaved, setIsPageSaved] = useState<boolean>(false);
  const currentPageId = getPageInfo(currentPageContext, "id");
  const currentPageUrl = getPageInfo(currentPageContext, "slug");
  const { siteSettings } = useSettings();
  useEffect(() => {
    const getSaved = async () => {
      if (user) {
        try {
          const results = await getSavedPages(getCookie("access_token"));
          const savePageIds = results?.result;

          if (savePageIds.length > 0) {
            setIsPageSaved(savePageIds.some((id) => id == currentPageId));
          }
        } catch (e) {
          console.error(e);
        }
      }
    };

    getSaved();
  }, [user, currentPageContext]);

  function getPageInfo(page, info) {
    if (!page) return null;
    const contents = page.split("|");
    switch (info) {
      case "id":
        return contents[0];
      case "slug":
        return contents[1];
    }
  }

  const handleSavePage = (event) => {
    event.preventDefault();

    if (isPageSaved) return;

    if (user) {
      savePage(
        sessionStorage.getItem("crm_campaign"),
        currentPageId,
        getCookie("access_token")
      );
      setIsPageSaved(true);
    } else {
      location.href = `/${siteSettings?.loginOrRegisterPage?.slug}/?returnUrl=${currentPageUrl}`;
    }
  };

  return (
    <a
      id="SaveToProfileLink"
      href="#"
      onClick={handleSavePage}
      className={theme["cta-save-to-my-profile"]}
      title={user ? saveToMyProfileText : loggedOutTitleText}
    >
      {isPageSaved ? alreadySavedToMyProfileText : saveToMyProfileText}
    </a>
  );
};

export default themr("SaveToMyProfile", styles)(SaveToMyProfile);
