import { DonateToolPaymentTypes } from "@customTypes/DonateToolPaymentTypes";
import { themr } from "@friendsofreactjs/react-css-themr";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { BlackbaudForms } from "./C283_DonateTool";
import styles from "./C283_DonateTool.module.scss";

export interface BlackbaudFormProps {
  journeyStep: number;
  externalUrl?: string;
  blackbaudForms: BlackbaudForms;
  giftAid?: boolean;
}

declare global {
  interface Window {
    bbox: any;
    bboxInit: any;
  }
}

export const BlackbaudForm = (props: {
  content: BlackbaudFormProps;
  theme?: any;
  anchor: string;
}) => {
  const { content, theme, anchor } = props;

  const { journeyStep, externalUrl, blackbaudForms, giftAid } = content;

  const isWindow = typeof window === "object";
  const urlParams = isWindow
    ? new URLSearchParams(window.location.search)
    : null;
  const activeTab = urlParams?.get("pt") || DonateToolPaymentTypes.SINGLE;

  const [blackbaudId] = useState(blackbaudForms[activeTab]);

  let bbox;
  if (typeof window !== "undefined") {
    bbox = window.bbox;
  }

  const bbRoot = useRef<HTMLDivElement>(null);

  useEffect(() => {
    loadDonationForm();
  }, [blackbaudId]);

  const handleTab = (e) => {
    const paymentType = e?.target?.dataset?.buttonItem;

    if (paymentType) {
      urlParams?.set("pt", paymentType);
      window.location.href = `${window.location.origin}${window.location.pathname}?${urlParams}${anchor}`;
    }
  };

  const loadDonationForm = () => {
    if (bbRoot.current) {
      bbRoot.current.innerHTML = "";
    }

    if (!window.bbox) {
      window.bboxInit = () => {
        bbox = window.bbox;
        bbox.showForm(blackbaudId);
      };

      (function () {
        var e = document.createElement("script");
        e.async = true;
        e.src = "https://bbox.blackbaudhosting.com/webforms/bbox-min.js";
        document.getElementsByTagName("head")[0].appendChild(e);
      })();
    } else {
      bbox.showForm(blackbaudId);
    }
  };

  return (
    <section className={theme["donation-form"]}>
      <div className={theme["donation-button-tab-wrapper"]}>
        {journeyStep === 2 && !externalUrl && (
          <>
            <button
              className={clsx(
                theme["cta-donate"],
                blackbaudForms?.single && theme["has--form"],
                activeTab === DonateToolPaymentTypes.SINGLE &&
                  theme["is--active"]
              )}
              aria-pressed={activeTab === DonateToolPaymentTypes.SINGLE}
              data-button-item={DonateToolPaymentTypes.SINGLE}
              onClick={(e) => {
                handleTab(e);
              }}
            >
              Single
            </button>
            <button
              className={clsx(
                theme["cta-donate"],
                blackbaudForms?.recurringDd && theme["has--form"],
                activeTab === DonateToolPaymentTypes.RECURRING_DD &&
                  theme["is--active"]
              )}
              aria-pressed={activeTab === DonateToolPaymentTypes.RECURRING_DD}
              data-button-item={DonateToolPaymentTypes.RECURRING_DD}
              onClick={(e) => {
                handleTab(e);
              }}
            >
              Recurring (Direct Debit)
            </button>
            <button
              className={clsx(
                theme["cta-donate"],
                blackbaudForms?.recurringCard && theme["has--form"],
                activeTab === DonateToolPaymentTypes.RECURRING_CARD &&
                  theme["is--active"]
              )}
              aria-pressed={activeTab === DonateToolPaymentTypes.RECURRING_CARD}
              data-button-item={DonateToolPaymentTypes.RECURRING_CARD}
              onClick={(e) => {
                handleTab(e);
              }}
            >
              Recurring (Card)
            </button>
          </>
        )}
      </div>
      <div
        id="bbox-root"
        className={!giftAid ? "no-gift-aid" : ""}
        ref={bbRoot}
      ></div>
    </section>
  );
};

export default themr("BlackbaudForm", styles)(BlackbaudForm);
