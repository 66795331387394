import { ContentfulImage } from "@customTypes/ContentfulImage";
import { ContentfulImageFormat } from "@customTypes/ContentfulImageFormat";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { FC } from "react";
import style from "./Image.module.scss";

interface ImageWrapperProps {
  image: ImageWrapperType;
  loading?: "eager" | "lazy";
  className?: string;
  width?: number;
  height?: number;
}

export const convertToImageWrapperType = (
  graphql: {
    url: string;
    width?: number;
    height?: number;
    contentType?: string;
  },
  altText = ""
) => {
  const { url, width, height, contentType } = graphql;

  return {
    fields: {
      altText,
      image: {
        fields: {
          file: {
            url,
            details: {
              image: { width, height },
            },
            contentType,
          },
        },
      } as ContentfulImage,
    },
  };
};

const ImageWrapper: FC<ImageWrapperProps> = ({
  className,
  image,
  loading = "lazy",
  width,
  height,
}) => {
  const {
    fields: {
      altText,
      image: {
        fields: {
          file: {
            url,
            details: {
              image: { width: originalWidth, height: originalHeight },
            },
            contentType,
          },
        },
      },
    },
  } = image;

  const generateSourceProps = (
    format: ContentfulImageFormat
  ): { srcSet: string; type: string } => {
    const type = `image/${format}`;

    let params = new URLSearchParams();
    params.set("fm", format);
    params.set("fit", "fill");
    params.set("f", "face");
    params.set("q", "70");

    if (width) params.set("w", width.toString());
    if (height) params.set("h", height.toString());

    const srcSet = `${url}?${params.toString()}`;

    return { srcSet, type };
  };

  return (
    <picture>
      <source {...generateSourceProps("avif")} />
      <source {...generateSourceProps("webp")} />
      <source srcSet={url} type={contentType} />
      <img
        className={`${className} ${style.img} ${style.developWrapper}`}
        src={url}
        alt={altText}
        loading={loading}
      />
    </picture>
  );
};

export default ImageWrapper;
