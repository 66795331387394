import { useEffect, useRef } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import RichTextFullWidth from "@components/C16_RichTextFullWidth/C16_RichTextFullWidth";
import styles from "./BlackbaudGenericForm.module.scss";
export interface BlackbaudGenericFormProps {
  formId: string;
  title?: string;
  description?: any;
}

declare global {
  interface Window {
    bbox: any;
    bboxInit: any;
  }
}

export const BlackbaudGenericForm = (props: {
  content: BlackbaudGenericFormProps;
}) => {
  const { content } = props;

  const { formId, title, description } = content;

  let bbox;
  if (typeof window !== "undefined") {
    bbox = window.bbox;
  }

  const bbRoot = useRef<HTMLDivElement>(null);

  useEffect(() => {
    loadForm();
  }, [formId]);

  const loadForm = () => {
    if (bbRoot.current) {
      bbRoot.current.innerHTML = "";
    }

    if (!window.bbox) {
      window.bboxInit = () => {
        bbox = window.bbox;
        bbox.showForm(formId);
      };

      (function () {
        var e = document.createElement("script");
        e.async = true;
        e.src = "https://bbox.blackbaudhosting.com/webforms/bbox-min.js";
        document.getElementsByTagName("head")[0].appendChild(e);
      })();
    } else {
      bbox.showForm(formId);
    }
  };
  return (
    <section className="component wrapper">
      {title ? <h3>{title}</h3> : null}
      {description ? (
        <RichTextFullWidth content={description} addBorder={false} />
      ) : null}
      <div id="bbox-root" ref={bbRoot} className={styles.container}>
        <ClipLoader
          color="#001e62"
          size={20}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </section>
  );
};

export default BlackbaudGenericForm;
