import richTextify from "@utilities/richtext";
import RichTinytify from "@utilities/tinymce";

const RichTextDisplay = ({ richText, tinyMceRichText }) => {
  return (
    <>
      {richText && richText?.content?.length
        ? richTextify(richText)
        : tinyMceRichText?.richText && <RichTinytify data={tinyMceRichText} />}
    </>
  );
};

export default RichTextDisplay;
