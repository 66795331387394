import parse, { Element, domToReact } from "html-react-parser";
import Link from "@components/Link/Link";
import { useEffect, useState } from "react";

function CSSstring(styles: string) {
  if (!styles) return {};

  return styles
    .split(";")
    .map((cur) => cur.split(":"))
    .reduce((acc, val) => {
      let [key, value] = val;
      key = key.replace(/-./g, (css) => css.toUpperCase()[1]);
      acc[key] = value;
      return acc;
    }, {});
}

export default function RichTextify({ data }) {
  const [markup, setMarkup] = useState<string>("");
  const { richText, content } = data;
  // let dom;

  useEffect(() => {
    const dom = new DOMParser().parseFromString(richText, "text/html");

    // combine reference and markup data
    content &&
      content?.forEach((ref) => {
        const { fields, sys } = ref.data.target;

        [...dom.querySelectorAll(`[data-id="${sys.id}"]`)].forEach(
          (el: any) => {
            if (el.tagName === "IMG") {
              el.src = fields?.file?.url;
              el.alt = fields?.title;
            }

            if (el.tagName === "A") {
              el.href = fields?.slug;
            }
          }
        );
      });

    setMarkup(dom.body.innerHTML);
  }, []);

  useEffect(() => {
    setIframeTracking();
  }, [markup]);

  function setIframeTracking() {
    if (typeof document !== "undefined") {
      if (
        sessionStorage.getItem("crm_campaign") ||
        sessionStorage.getItem("EventCode")
      ) {
        var mbaY1iframe = document.getElementById("MBA1YFORM");

        if (mbaY1iframe) {
          const campaignCode = sessionStorage.getItem("crm_campaign");
          const eventCode = sessionStorage.getItem("EventCode");
          const currentSrc = mbaY1iframe.getAttribute("src");

          if (currentSrc?.includes("?")) {
            if (
              !currentSrc?.includes("campaign_code") &&
              !currentSrc?.includes("event_code")
            ) {
              mbaY1iframe.setAttribute(
                "src",
                currentSrc +
                  "&campaign_code=" +
                  campaignCode +
                  "&event_code=" +
                  eventCode
              );
            }
          } else {
            mbaY1iframe.setAttribute(
              "src",
              currentSrc +
                "?campaign_code=" +
                campaignCode +
                "&event_code=" +
                eventCode
            );
          }
        }
      }
    }
  }

  const getLink = (attributes, attribs, children, pageLoad = false) => {
    const href = pageLoad
      ? attribs.href.replace("../", `${process.env.NEXT_PUBLIC_URL}/`)
      : attribs.href;
    return (
      <a
        className={attribs.class}
        style={CSSstring(attribs.style)}
        href={href}
        {...attributes}
      >
        {domToReact(children)}
      </a>
    );
  };

  // return react components from markup string
  return (
    <>
      {parse(markup, {
        replace: (domNode) => {
          const { name, attribs, children } = domNode as Element;
          const { ...attributes } = attribs;
          if (name === "option") {
            return <option {...attribs}>{domToReact(children)}</option>;
          }

          if (name === "a" && attribs.href !== "#") {
            delete attributes.class;
            delete attributes.href;
            delete attributes.style;
            if (attribs["data-page-load"]) {
              return getLink(attributes, attribs, children, true);
            } else {
              return (
                <Link href={attribs.href || "/"} passHref={true}>
                  {getLink(attributes, attribs, children)}
                </Link>
              ); // use next/link for links
            }
          }
          if (name === "img") {
            attribs.lazy = "lazy"; // force lazy load on richtext images
            return domNode;
          }
        },
      })}
    </>
  );
}
