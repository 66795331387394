import C30_SaveToMyProfile from "@components/C30_SaveToMyProfile/C30_SaveToMyProfile";
import styles from "./Breadcrumb.module.scss";
import Image from "@components/Image/NextImage";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const SocialShare = () => {
  const router = useRouter();
  const [url, setUrl] = useState("");

  useEffect(() => {
    const url = document.URL;
    setUrl(url);
  }, [router]);

  return (
    <div className={styles["social-sharing"]}>
      <ul className={styles["social-links"]}>
        <li>
          <a
            aria-label="Facebook - Opens a new window"
            href={`https://www.facebook.com/sharer/sharer.php?u=${url}&amp;quote=${url}`}
            target="_blank"
            rel="noreferrer"
          >
            <Image
              alt="Facebook logo"
              src="https://images.london.edu/hxo16fanegqh/1b4d0f57-c781-4ea1-841c-a5203a35f267-asset/d31739c0823c7001ea7a74e77bbc176c/facebooknew.png"
              width={60}
              height={60}
              className={styles.icon}
              quality={100}
            />
          </a>
        </li>
        <li>
          <a
            aria-label="Linkedin - Opens a new window"
            href={`https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&shareUrl=${url}&amp;title=Home&amp;summary=We+deliver+postgraduate+business+education+offering+one+of+the+world%27s+best+MBA+programmes%2c+Masters+and+PhD+degrees%2c+plus+short+courses+for+executives.&amp;source=${url}`}
            target="_blank"
            rel="noreferrer"
          >
            <Image
              alt="Linkedin logo"
              src="https://images.london.edu/hxo16fanegqh/5EDQ0MzjHy5LkwmGfCPJHu/e45705df18c30cd7414df3ed65f01a2c/linkedin.svg"
              width={60}
              height={60}
              className={styles.icon}
              quality={100}
            />
          </a>
        </li>
        <li>
          <a
            aria-label="Twitter - Opens a new window"
            href={`https://twitter.com/intent/tweet?text=${url}`}
            target="_blank"
            rel="noreferrer"
          >
            <Image
              alt="twitter logo"
              src="https://images.london.edu/hxo16fanegqh/1kw4adgk02bOLSXw9JEY60/b05c208999c1838a1b3cdfae9a513f62/12732_x_logo_circle_white_40x40.webp"
              width={60}
              height={60}
              className={styles.icon}
              quality={100}
            />
          </a>
        </li>
      </ul>
      <C30_SaveToMyProfile />
    </div>
  );
};

export default SocialShare;
