import { useEffect, useState } from "react";
import { themr } from "@friendsofreactjs/react-css-themr";
import styles from "./C01.2_HeaderSearch.module.scss";
import Cta from "@components/Cta/Cta";
import CloseSvg from "../../public/image/svg/cross.svg";
import ArrowSvg from "../../public/image/svg/arrow.svg";
import { GeneralLink } from "@customTypes/generalLink";
import { useRouter } from "next/router";
import clsx from "clsx";

export interface HeaderSearchProps {
  areaOfInterestLinks: GeneralLink[];
  areaOfInterestText: string;
  searchHelpText: string;
  searchPlaceholderText: string;
  searchResultsUrl: any;
}

export const HeaderSearch = (props: {
  content: HeaderSearchProps;
  show: boolean;
  close: Function;
  theme?: any;
}) => {
  const { content, theme, show, close } = props;
  const {
    areaOfInterestLinks,
    areaOfInterestText,
    searchHelpText,
    searchPlaceholderText,
    searchResultsUrl,
  } = content;

  const router = useRouter();
  const [searchInput, setSearchInput] = useState("");
  const search = () => {
    if (router.asPath.includes("/search")) {
      router
        .push(`/${searchResultsUrl?.fields?.url}#q=${searchInput}`)
        .then(() => router.reload());
    } else {
      router.push(`/${searchResultsUrl?.fields?.url}#q=${searchInput}`);
    }
  };

  useEffect(() => {
    close();
  }, [router]);

  const handleInputKeypress = (e) => {
    if (e.code === "Enter") {
      search();
    }
  };

  const handleClose = () => {
    close();
  };

  return (
    <div className={clsx(theme.search, show && theme.show)} aria-modal="true">
      <div className={theme.inside}>
        <p className={theme.helper} id="search-label">
          {searchHelpText}
        </p>
        <div className={theme.input}>
          <input
            type="text"
            placeholder={searchPlaceholderText}
            aria-describedby="search-label"
            name="searchTerm"
            autoComplete="off"
            aria-label="Search"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyPress={handleInputKeypress}
          />
          <button
            onClick={search}
            type="submit"
            className="icon-arrow"
            aria-label="send"
            data-grunticon-embed
          >
            <ArrowSvg />
          </button>
        </div>

        <div className={theme.links}>
          <p className={theme.helper}>{areaOfInterestText}</p>
          {areaOfInterestLinks && (
            <ul>
              {areaOfInterestLinks?.map((link, index) => (
                <li key={`${link.fields.linkText}${index}`}>
                  <Cta
                    type="tertiary"
                    icon="icon-arrow"
                    url={link.fields.url}
                    linkText={link.fields.linkText}
                    light
                    preventDefault={false}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div
        className={theme.close}
        tabIndex={0}
        aria-label="close search"
        onClick={handleClose}
      >
        <CloseSvg />
      </div>
    </div>
  );
};

export default themr("HeaderSearch", styles)(HeaderSearch);
