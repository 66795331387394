import Arrow from "../../public/assets2019/img/svg/arrow-carousel.svg";
import { useEffect, useRef, useState } from "react";
import styles from "./CountryStateSection.module.scss";
import clsx from "clsx";
import { themr } from "@friendsofreactjs/react-css-themr";
import { getCountries, getCountryStates } from "../../services/forms";
import useOnClickOutside from "../../utils/hooks/useOnClickOutside";
import { Controller } from "react-hook-form";
import { CountryStateProps } from "@components/CountryStateSection/interfaces";

const CountryStateSection = (props: CountryStateProps) => {
  const {
    theme,
    register,
    errors,
    isNationalityVariant,
    isShowRelatedStateField,
    countryOfResidenceLabelText,
    countryStateLabelText,
    countryInputValue,
    setCountryInputValue,
    countries,
    setCountries,
    selectedCountry,
    setSelectedCountry,
    showCountriesList,
    setShowCountriesList,
    countriesStateList,
    setCountriesStateList,
    selectedCountryState,
    setSelectedCountryState,
    control,
    setValue,
    countryValidationMsg,
  } = props;

  const inputName = !isNationalityVariant
    ? "selectedCountry"
    : "selectedNationalCountry";

  const hasError = errors && errors[inputName];

  const [error, setError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const hasCountryMatch = (value: string) =>
    countries && countries.find((country) => country === value);

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        if (countryInputValue.length > 0) {
          const countriesResponse = await getCountries(countryInputValue);
          setCountries(countriesResponse);
          !countriesResponse.length ? setError(true) : setError(false);
        }
      } catch (e) {
        console.log("Faulty request");
      }
    };
    if (selectedCountry !== countryInputValue) {
      fetchCountryData();
    }
    if (countryInputValue) {
      setIsFocused(true);
    }
  }, [countryInputValue]);

  useEffect(() => {
    if (!selectedCountry || selectedCountry?.length <= 0) return;
    const fetchCountryStateData = async () => {
      try {
        const selectedCountryState = await getCountryStates(selectedCountry);
        setCountriesStateList && setCountriesStateList(selectedCountryState);
      } catch (e) {
        console.log("Faulty request");
      }
    };

    if (isShowRelatedStateField) {
      fetchCountryStateData();
    } else {
      setCountriesStateList && setCountriesStateList([]);
    }

    setCountryInputValue(selectedCountry);
    setValue(inputName, selectedCountry);
  }, [selectedCountry]);

  useEffect(() => {
    if (countries) {
      setShowCountriesList(true);
    } else if (countryInputValue === selectedCountry || !countries) {
      setShowCountriesList(false);
    }
  }, [countries]);

  const handleCountriesChange = (event) => {
    setCountryInputValue(event.target.value);
    if (hasCountryMatch(event.target.value)) {
      setError(false);
    } else {
      setValue(inputName, null);
      setShowCountriesList(true);
    }

    if (!countries) {
      setShowCountriesList(false);
    }
  };

  const handleCountryOptionSelect = (country) => {
    setCountryInputValue(country);
    setSelectedCountry(country);
    setValue(inputName, country);
    setError(false);
    setShowCountriesList(false);
  };

  const handleOnBlur = () => {
    if (countries && !hasCountryMatch(countryInputValue)) {
      setError(true);
    } else {
      setSelectedCountry(countryInputValue);
    }

    setShowCountriesList(false);
  };

  const handleOnFocus = () => {
    if (!countryInputValue) {
      setError(true);
      setIsFocused(true);
      setShowCountriesList(false);
      return;
    }
    setIsFocused(true);
    setShowCountriesList(false);
  };

  const handleCountryStateChange = (e) => {
    setSelectedCountryState && setSelectedCountryState(e.target.value);
  };

  const fieldRef: any = useRef();

  useOnClickOutside(fieldRef, () => handleOnBlur());

  const getCountryValidation = () => {
    if (countryValidationMsg) return countryValidationMsg;
    return !isNationalityVariant
      ? "Country of residence is required"
      : "Country of nationality is required";
  };

  return (
    <div
      className={clsx(
        "form",
        "form-row",
        theme["form-row"],
        "field-row",
        theme["country-section"],
        isFocused && "focused",
        error && "error",
        hasError && !countryInputValue && "error"
      )}
    >
      <div className="form-label base4">
        <div className="label">
          <label htmlFor="HomeCountry">{countryOfResidenceLabelText}</label>
        </div>
      </div>
      <div className="form-field base6">
        <div className="field" ref={fieldRef}>
          <Controller
            name={inputName}
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field }) => (
              <>
                <input
                  name={inputName}
                  ref={register(inputName).ref}
                  className={clsx("smart-focus", theme["homecountry-box"])}
                  type="text"
                  value={countryInputValue}
                  autoComplete="off"
                  onChange={handleCountriesChange}
                  onFocus={handleOnFocus}
                />
                {showCountriesList && countries?.length > 0 && (
                  <ul className={theme["countries-wrapper"]}>
                    {countries &&
                      countries?.length &&
                      countries?.map((country, index) => (
                        <li
                          key={`${country}${index}`}
                          className={theme["countries-item"]}
                          value={country}
                          {...register(getCountryValidation())}
                          onClick={() => handleCountryOptionSelect(country)}
                        >
                          {country}
                        </li>
                      ))}
                  </ul>
                )}
              </>
            )}
          />
        </div>

        {(error || (hasError && !countryInputValue)) && (
          <div className="form-row error">
            <div className="form-field base6">
              <div
                className={clsx(
                  "field",
                  "message-wrapper",
                  "inline-message",
                  "field_error_msg"
                )}
                tabIndex={0}
              >
                <p className={clsx("message", "active")}>
                  {!countryInputValue || countryInputValue?.length <= 0
                    ? getCountryValidation()
                    : "Unknown country entered"}
                </p>
                {isNationalityVariant}
              </div>
            </div>
          </div>
        )}
      </div>

      {!error &&
        countryInputValue?.length > 0 &&
        countriesStateList &&
        countriesStateList?.length > 0 && (
          <div id="homestatediv" className={theme["country-state_wrapper"]}>
            <div className="form-row field-row select-wrapper">
              <div className="form-label base4">
                <div className="label">
                  <label htmlFor="CountryState">{countryStateLabelText}</label>
                </div>
              </div>
              <div className="form-field base6">
                <div className="field select">
                  {
                    <>
                      <select
                        className="homestate-list input-validation-error"
                        id="CountryState"
                        name="CountryState"
                        aria-invalid="false"
                        autoComplete="off"
                        value={selectedCountryState}
                        {...register(
                          `${
                            !isNationalityVariant
                              ? "selectedState"
                              : "selectedNationalState"
                          }`
                        )}
                        onChange={handleCountryStateChange}
                      >
                        <option value="">Select</option>
                        {countriesStateList &&
                          countriesStateList?.map((option, index) => (
                            <option value={option} key={`${option}${index}`}>
                              {option}
                            </option>
                          ))}
                      </select>
                    </>
                  }
                  <div className="tick icon-chevron">
                    <i className="icon-chevron">
                      <Arrow></Arrow>
                    </i>
                  </div>
                </div>

                {error && (
                  <div className="form-row error">
                    <div className="form-field base6">
                      <div
                        className={clsx(
                          "field",
                          "message-wrapper",
                          "inline-message",
                          "field_error_msg"
                        )}
                        tabIndex={0}
                      >
                        <p className={clsx("message", "active")}>
                          You must select a County/State
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default themr("CountryStateSection", styles)(CountryStateSection);
