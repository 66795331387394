import { format, parseISO } from "date-fns";
import ImageWrapper, {
  convertToImageWrapperType,
} from "@components/Image/ImageWrapper";
import SocialShare from "../C285_SocialShare/C285_SocialShare";
import { themr } from "@friendsofreactjs/react-css-themr";

import CalSvg from "../../public/image/svg/calendar.svg";
import LocationSvg from "../../public/image/svg/location.svg";
import DurationSvg from "../../public/image/svg/duration.svg";

import styles from "./Tiles.module.scss";

export interface TileProps {
  withImage: boolean;
  reducedContent: boolean;
  model: any;
  theme?: any;
  tags?: any[];
}

const cleanTag = (tag) => {
  return tag.split(":").pop();
};

export const ArticleTile = themr(
  "ArticleTile",
  styles
)(({ withImage, reducedContent, model, theme, tags }: TileProps) => {
  return (
    <div className={theme["article-panel"]}>
      <div className={theme["article-panel-content"]}>
        <div className={theme["wrapper-image"]}>
          {withImage && model.articleThumbnail && (
            <a href={model.slug}>
              <ImageWrapper
                image={convertToImageWrapperType(
                  model.articleThumbnail.image,
                  model.articleThumbnail.altText
                )}
              />
            </a>
          )}
        </div>
        <div className={theme.text}>
          <p className={theme["primary-tag"]}>
            THINK AT LONDON BUSINESS SCHOOL
          </p>
          <h2 className="heading-rhs text-decoration-border">
            <a href={model.slug}>{model.articleTitle}</a>
          </h2>
          {!reducedContent && (
            <>
              <p className={`${theme["sub-heading"]} medium-light`}>
                {format(parseISO(model.articlePublishedDate), "d MMMM yyyy")}
              </p>
              <p className={`${theme.copy} medium-light`}>
                {model.articleDescription}
              </p>
              <p className={theme["info-field"]}>
                By{" "}
                {model.authorsListCollection?.items
                  ?.map((author) => author.articleAuthorName)
                  .join(", ")}
              </p>

              <footer>
                {model.articleResearchArea && (
                  <div className="component tag cf">
                    {model.articleResearchArea.map((tag, i) => (
                      <a
                        key={`${cleanTag(tag)}${i}`}
                        href="@topic.SearchPageConstructedUrl"
                      >
                        {cleanTag(tag)}
                      </a>
                    ))}
                  </div>
                )}
                {tags && (
                  <div className="component tag cf">
                    {tags.map((tag, i) => (
                      <a
                        key={`${cleanTag(tag.name)}${i}`}
                        href={`/think?topics=${cleanTag(tag.name)}`}
                      >
                        {cleanTag(tag.name)}
                      </a>
                    ))}
                  </div>
                )}
                <SocialShare url={`${document.location.host}/${model.slug}`} />
              </footer>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export const EventTile = themr(
  "EventTile",
  styles
)(({ withImage, reducedContent, model, theme }: TileProps) => {
  theme = theme || styles;
  return (
    <div className={theme["promo-events"]}>
      {withImage && model.eventTopImage && (
        <a href={`/${model.slug}`}>
          <div className={theme["icon-on-image"]}>
            <span className={theme["month-on-image"]}>
              {format(parseISO(model.eventStarts), "MMM")}
            </span>
            <span className={theme["day-on-image"]}>
              {format(parseISO(model.eventStarts), "dd")}
            </span>
          </div>
          <ImageWrapper
            image={convertToImageWrapperType(
              model.eventTopImage?.image,
              model.eventTopImage?.altText
            )}
          />
        </a>
      )}

      <div className={theme.details}>
        <p className={theme["primary-tag"]}>{model.eventType}</p>
        <h2>
          <a href={`/${model.slug}`}>{model.eventTitle}</a>
        </h2>

        {!reducedContent && (
          <>
            <p className={theme["text-with-icon"]}>
              <i>
                <CalSvg />
              </i>
              {format(parseISO(model.eventStarts), "EEEE d MMMM yyyy")}
            </p>

            <p className={theme["text-with-icon"]}>
              <i>
                <DurationSvg />
              </i>
              {format(parseISO(model.eventStarts), "HH:mm")}
            </p>

            <p className={theme["text-with-icon"]}>
              <i>
                <LocationSvg />
              </i>
              {model.eventLocationName}
            </p>

            <p className={theme["summary-text"]}>
              {model.eventShortDescription}
            </p>

            <footer>
              <SocialShare url={`${document.location.host}/${model.slug}`} />
            </footer>
          </>
        )}
      </div>
    </div>
  );
});

export const NewsTile = themr(
  "NewsTile",
  styles
)(({ withImage, reducedContent, model, theme, tags }: TileProps) => {
  return (
    <div className={theme["article-panel"]}>
      <div className={theme["article-panel-content"]}>
        <div className={theme["wrapper-image"]}>
          {withImage && model.thumbnailImage && (
            <a href={`/${model.slug}`}>
              <ImageWrapper
                image={convertToImageWrapperType(
                  model.thumbnailImage?.image,
                  model.thumbnailImage?.altText
                )}
              />
            </a>
          )}
        </div>

        <div className={theme.text}>
          <p className={theme["primary-tag"]}>NEWS</p>

          <h2 className="heading-rhs text-decoration-border">
            <a href={`/${model.slug}`}>{model.newsTitle}</a>
          </h2>

          {!reducedContent && (
            <>
              <p className={`${theme["sub-heading"]} medium-light`}>
                {format(parseISO(model.date), "EEEE d MMMM yyyy")}
              </p>
              <p className={`${theme.copy} medium-light`}>
                {model.shortDescription}
              </p>

              <footer>
                {tags && (
                  <div className="component tag cf">
                    {tags.map((tag, i) => (
                      <a
                        key={`${cleanTag(tag.name)}${i}`}
                        href={`/news?topics=${cleanTag(tag.name)}`}
                      >
                        {cleanTag(tag.name)}
                      </a>
                    ))}
                  </div>
                )}

                <SocialShare url={`${document.location.host}/${model.slug}`} />
              </footer>
            </>
          )}
        </div>
      </div>
    </div>
  );
});

export default { ArticleTile, EventTile, NewsTile };
