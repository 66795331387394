import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { themr } from "@friendsofreactjs/react-css-themr";
import { useRouter } from "next/router";
import ClipLoader from "react-spinners/ClipLoader";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

import {
  getPersonalDetails,
  getTitles,
  getJobLevel,
  setRegisterInteresFormLoggedOut,
  setRegisterInterestFormLoggedIn,
  getBroadIndustries,
  getInstitutions,
} from "../../services/forms";
import Grid from "@components/Grid/Grid";
import { UserContext } from "../../context/user";
import { LOGIN_URL, BROCHURE_NOT_FOUND } from "../../constants";

import InstitutionSection from "@components/InstitutionSection/InstitutionSection";
import FormField from "@components/Form/FormFields/FormFields";
import FieldJSX from "@components/Form/FieldJSX/FieldJSX";
import { mapProgrammeTypeToInt } from "@utilities/mapBrochureType";
import CountryStateSection from "@components/CountryStateSection/CountryStateSection";
import ProgrammesListSection from "@components/ProgrammesListSection/ProgrammesListSection";
import FormPrivacySection from "@components/FormPrivacySection/FormPrivacySection";
import {
  programmeTypeGroupFieldsInfo,
  permanentFields,
  programmesListData,
} from "@components/RegisterInterestForm/constants";
import { RegisterInterestFormProps } from "@components/RegisterInterestForm/interfaces";

import LinkedIn from "@components/LinkedIn/LinkedIn";
import Checkbox from "@components/Checkbox/Checkbox";
import { countryCodesData } from "@components/CountryNumber/CountryCodes";
import CountryNumber from "@components/CountryNumber/CountryNumber";
import { useSettings } from "@utilities/context/settings";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import { handleSubmitFormErrors } from "@utilities/handleSubmitFormErrors";

import styles from "./RegisterInterestForm.module.scss";
import setGADataLayer from "@utilities/setGADataLayer";
import { SiteSettings } from "@customTypes/SiteSettings";
import { getFormInitialValues } from "@components/RegisterInterestForm/InitialData";
import { prepareSubmitData, getBrochureData } from "./utils";
let firstInteract = 0;

interface RegisterInterestIntroText {
  title: string;
  body: string;
}

export const RegisterInterestForm = (props: {
  content: RegisterInterestFormProps;
  theme?: any;
}) => {
  const { content, theme } = props;
  const {
    countryOfResidenceLabelText,
    countryStateLabelText,
    nationalityOfLabelText,
    programmesHeadingText,
    programmesHintText,
    hrProfessionalText,
    countryCodeLabelText,
    telephoneNumberPlaceholderText,
    telephoneNumberHintText,
    failedMessage,
    embagPrivacyInformRadioYes,
    embagPrivacyInformRadioNo,
    brochureTypes,
    registerInterestLoggedInTitleText,
    successPageUrl,
    introTextNotLoggedInListItemsText,
    notLoggedInCreateWebProfileText,
  } = content;

  const appInsights = useAppInsightsContext();
  const [submittedUserEmail, setSubmittedUserEmail] = useState<string>("");

  const [genuinePath, setGenuinePath] = useState<any>("");
  const [userPersonalDetails, setUserPersonalDetails] = useState<any>(null);
  const [titles, setTitles] = useState<any>("");
  const [industries, setIndustries] = useState<any>("");
  const [institutions, setInstitutions] = useState<any>("");
  const [seniorityLevels, setSeniorityLevels] = useState<any>("");
  const [error, setError] = useState<boolean>(false);
  const [programme, setProgramme] = useState<string>("");
  const [mappedBrochureType, setMappedBrochureType] = useState<any>("");
  const [brochureUrlLink, setBrochureUrlLink] = useState<string>("");
  const [handledSubmitFormErrors, setHandledSubmitFormErrors] =
    useState<Array<string> | null>(null);
  const [programmesSelectedTypes, setProgrammesSelectedTypes] = useState<any>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [isHrProfessional, setIsHrProfessional] = useState<boolean>(false);
  const [countryInputValue, setCountryInputValue] = useState<any>("");
  const [countries, setCountries] = useState<any>(null);
  const [titlesSelectValue, setTitlesSelectValue] = useState<any>(null);
  const [industriesSelectValue, setIndustriesSelectValue] = useState<any>(null);
  const [institutionsSelectValue, setInstitutionsSelectValue] =
    useState<any>(null);
  const [seniorityLevelsSelectValue, setSeniorityLevelsSelectValue] =
    useState<any>(null);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [countriesStateList, setCountriesStateList] = useState<any>([]);
  const [selectedCountryState, setSelectedCountryState] = useState<string>("");
  const [nationalityInputValue, setNationalityInputValue] = useState<any>("");
  const [nationalityCountries, setNationalityCountries] = useState<any>(null);
  const [loggedInStayInformed, setLoggedInStayInformed] = useState<any>(null);
  const [selectedNationalCountry, setSelectedNationalCountry] =
    useState<string>("");
  const [showNationalCountriesList, setShowNationalCountriesList] =
    useState<boolean>(false);
  const [selectedDialCode, setSelectedDialCode] = useState<string>("");
  const [numberValue, setNumberValue] = useState<string>("");
  const [selectedIdValues, setSelectedIdValues] = useState<any>({
    title: "",
    industry: "",
    seniorityLevel: "",
  });
  const [showCountriesList, setShowCountriesList] = useState<boolean>(false);
  const [loggedInUser, setLoggedInUser] = useState<any>(null);

  const { user } = useContext(UserContext);
  const router = useRouter();
  const {
    register,
    handleSubmit,
    reset,
    formState,
    watch,
    control,
    setValue,
    clearErrors,
  } = useForm({
    mode: "onBlur",
  });

  const { apiErrorMessages, siteSettings } = useSettings();
  const {
    embagSiteUrl,
    embagDownloadBrochureLinkUrl,
    embagDownloadBrochureSuccessPageUrl,
  } = siteSettings as SiteSettings;
  const [brochureContentId, setBrochureContentId] = useState<string>("");

  const [formIntroTextTitle, setFormIntroText] = useState<string | undefined>();
  const [formIntroTextBody, setFormIntroTextBody] = useState<
    string | undefined
  >();
  const [formTitleText, setFormTitleText] = useState<string | undefined>();
  const [formId, setFormId] = useState<string>("mainform");
  const [successPageUrlState, setSuccessPageUrl] = useState<string>("/404");
  const [canContactByEmail, setCanContactByEmail] = useState<boolean>(false);

  const setFormIntroCopyAndSuccessUrl = (content) => {
    //check with content if it is different and set it accordingly
    const title =
      loggedInUser && content.registerInterestFormLoggedInTitleText
        ? content.registerInterestFormLoggedInTitleText
        : content.registerInterestFormTitleText;

    const introTextTitle =
      loggedInUser && content.introLoggedInText
        ? content.introLoggedInText
        : content.introText;

    setFormId(content.registerInterestFormTitleText.replace(/\s+/g, "-"));
    setFormTitleText(title);
    setFormIntroText(introTextTitle);
    setSuccessPageUrl(content.successPageUrl);
    setFormIntroTextBody(content.introTextNotLoggedInListItemsText);
  };

  useEffect(() => {
    setFormIntroCopyAndSuccessUrl(content);
  }, [router, brochureTypes, loggedInUser]);

  useEffect(() => {
    user ? setLoggedInUser(true) : setLoggedInUser(null);
  }, [user]);

  useEffect(() => {
    const subscription = watch((e, a) => {
      if (a.type === "change" && firstInteract < 1) {
        setGADataLayer({
          event: "formStart",
          formName: content.registerInterestFormTitleText,
        });
        firstInteract++;
        return () => subscription.unsubscribe();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const regularReferPage = router?.query?.referPage || router?.asPath;
    const prevUrlPath =
      router?.query?.programmeTypeGroup === "EMBAG"
        ? embagSiteUrl
        : regularReferPage;
    setGenuinePath(prevUrlPath);
  }, [programme, router?.query]);

  useEffect(() => {
    const loadInitialFormData = async () => {
      try {
        if (loggedInUser) {
          const personalDetails = await getPersonalDetails();
          setUserPersonalDetails(personalDetails);
          setCanContactByEmail(
            loggedInUser && personalDetails?.result?.isStayInformed
          );
        } else {
          const titles = await getTitles();
          const industries = await getBroadIndustries();
          const seniorityLevels = await getJobLevel();
          const institutions = await getInstitutions();
          setTitles(titles);
          setIndustries(industries);
          setSeniorityLevels(seniorityLevels);
          setInstitutions(institutions);
        }
      } catch (e) {
        setError(true);
      }
    };

    loadInitialFormData();
  }, [loggedInUser]);

  useEffect(() => {
    if (loggedInUser) {
      setNumberValue(userPersonalDetails?.result?.telephoneNumber ?? "");
      setCountryInputValue(userPersonalDetails?.result?.address?.homeCountry);
      setSelectedCountry(userPersonalDetails?.result?.address?.homeCountry);
      setSelectedCountryState(
        userPersonalDetails?.result?.address?.countryState
      );
      setNationalityInputValue(userPersonalDetails?.result?.nationality);
      setSelectedNationalCountry(userPersonalDetails?.result?.nationality);
      setTitlesSelectValue(userPersonalDetails?.result?.title);
      setIndustriesSelectValue(userPersonalDetails?.result?.industry);
      setSeniorityLevelsSelectValue(userPersonalDetails?.result?.jobLevel);
      setLoggedInStayInformed(userPersonalDetails?.result?.isStayInformed);
      setSelectedIdValues({
        title: userPersonalDetails?.result?.title,
        industry: userPersonalDetails?.result?.broadIndustry,
        seniorityLevel: userPersonalDetails?.result?.jobLevel,
      });
    }
  }, [loggedInUser, userPersonalDetails]);

  const isWindow = typeof window === "object";
  const urlParams = isWindow
    ? new URLSearchParams(window.location.search)
    : null;

  const urlBrochureType = urlParams?.get("brochureType")?.toLowerCase() || "";
  const programmeTypeCode = urlParams?.get("programmeTypeCode") || "";
  const programmeTypeGroup = urlParams?.get("programmeTypeGroup") || "";

  useEffect(() => {
    if (router?.query?.programmeTypeGroup) {
      setProgramme(router.query.programmeTypeGroup as string);
    }

    // if (router?.query?.brochureType && urlBrochureType) {
    //   const mappedBrochureType = mapProgrammeTypeToInt(urlBrochureType);
    //   setMappedBrochureType(mappedBrochureType);
    // }
  }, [router?.query]);

  //dont need this I believe

  useEffect(() => {
    getBrochureData(
      programmeTypeCode,
      programmeTypeGroup,
      urlBrochureType,
      embagDownloadBrochureLinkUrl,
      setBrochureContentId,
      setBrochureUrlLink
    );
  }, [router]);

  useEffect(() => {
    if (userPersonalDetails) {
      const inputProp = [...FormFields].reduce(
        (o, key) => ({ ...o, [key.propertyName]: key.dataText }),
        {}
      );
      reset(inputProp);
    }
  }, [userPersonalDetails]);

  const FormFields = getFormInitialValues(
    content,
    userPersonalDetails,
    titles,
    industries,
    institutions,
    seniorityLevels,
    user,
    watch,
    titlesSelectValue,
    setTitlesSelectValue,
    industriesSelectValue,
    setIndustriesSelectValue,
    institutionsSelectValue,
    setInstitutionsSelectValue,
    seniorityLevelsSelectValue,
    setSeniorityLevelsSelectValue
  );

  const sendGAEvent = (event) => {
    setGADataLayer({
      event,
      formName: formTitleText,
      programmeCode: router?.query?.programmeTypeCode,
    });
  };

  const getDataForSubmit = (data) => {
    return prepareSubmitData(
      data,
      selectedIdValues,
      router,
      canContactByEmail,
      genuinePath,
      isHrProfessional,
      selectedNationalCountry,
      selectedCountry,
      selectedCountryState,
      mappedBrochureType,
      brochureContentId,
      institutions
    );
  };

  const trackErrorInAppInsights = (
    userEmail: string,
    handledSubmitFormErrors: string[] | null
  ) => {
    const errorDescription = `Programme page: ${router.query.referPage}. User email: ${userEmail}.`;
    appInsights.trackEvent({
      name: BROCHURE_NOT_FOUND,
      properties: {
        error: !!handledSubmitFormErrors
          ? handledSubmitFormErrors[0]
          : BROCHURE_NOT_FOUND,
        errorDescription,
      },
    });
  };

  const onSubmit = async (data) => {
    if (countries && !countries?.length && !!selectedCountry) return;
    if (
      nationalityCountries &&
      !nationalityCountries?.length &&
      !!selectedNationalCountry
    )
      return;

    setHandledSubmitFormErrors(null);
    setLoading(true);
    sendGAEvent("formSubmit");
    const resultFormData = getDataForSubmit(data);
    const setRegisterInterestMethod = loggedInUser
      ? setRegisterInterestFormLoggedIn
      : setRegisterInteresFormLoggedOut;

    try {
      setSubmittedUserEmail(data?.emailAddress);

      const downloadBrochure = await setRegisterInterestMethod(
        sessionStorage.getItem("crm_campaign"),
        resultFormData
      );
      setLoading(false);

      if (downloadBrochure.status !== 201) {
        const handledSubmitFormErrors = handleSubmitFormErrors(
          downloadBrochure,
          apiErrorMessages
        );
        setHandledSubmitFormErrors(handledSubmitFormErrors);
        window.scrollTo(0, 0);
      } else {
        // success submit
        sendGAEvent("formComplete");
        router.push({
          pathname: successPageUrl,
          query: {
            programme: router?.query?.programmeTypeCode,
            // returnToPreviousPageUrl: genuinePath,
            referPage: genuinePath,
          },
        });
      }
    } catch (e) {
      const handledSubmitFormErrors = handleSubmitFormErrors(
        null,
        apiErrorMessages
      );
      setLoading(false);
      setHandledSubmitFormErrors(handledSubmitFormErrors);
    }
  };

  const renderRedBulletListItems = () => {
    let data = formIntroTextBody?.split(";");

    let bulletListItems: React.JSX.Element[] = [];

    data?.forEach((item, index) => {
      bulletListItems.push(
        <li key={index} className={styles.riRedBulletListItem}>
          {item}
        </li>
      );
    });

    return <ul className={styles.riRedBulletList}>{bulletListItems}</ul>;
  };

  const renderFormSection = () => {
    if (!programme || (loggedInUser && !userPersonalDetails))
      return (
        <div className={styles.spinner}>
          <ClipLoader
            color="#001e62"
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      );

    return (
      <section
        className={clsx(
          theme.component,
          theme["brochure-form"],
          theme["form"],
          "form",

          theme.cf
        )}
      >
        <form id={formId} className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className={theme["fields-floated"]}>
            <>
              <Grid row>
                <Grid column sm={12}>
                  {/* <p>{formIntroText}</p> */}
                  {!loggedInUser ? (
                    <div>
                      <p className={styles.riRedBulletListTitle}>
                        {" "}
                        {formIntroTextTitle}
                      </p>

                      {renderRedBulletListItems()}
                    </div>
                  ) : (
                    <div className={styles.introLoggedInText}>
                      {formIntroTextTitle}
                    </div>
                  )}

                  <br />
                  <br />
                  {!loggedInUser && (
                    <p>
                      <span>{notLoggedInCreateWebProfileText}</span> Already
                      registered?{" "}
                      <a className="underline" href={LOGIN_URL}>
                        Log in here.
                      </a>
                    </p>
                  )}
                </Grid>
              </Grid>

              <LinkedIn formId={formId} />
              <section className={theme["form-group-wrapper"]}>
                <div className={theme["form-row"]}></div>

                {FormFields.map((field, index) => {
                  const programmeFieldsList =
                    programmeTypeGroupFieldsInfo[programme];
                  const isShowField =
                    programmeFieldsList &&
                    programmeFieldsList[field.propertyName]?.enabled;

                  if (!isShowField) return null;

                  if (field.propertyName === "countryOfResidence") {
                    return (
                      <CountryStateSection
                        key={`${field.labelText}${index}`}
                        isShowRelatedStateField={
                          programmeFieldsList["countryState"].enabled
                        }
                        isNationalityVariant={false}
                        name={"countryOfResidence"}
                        programme={programme}
                        register={register}
                        control={control}
                        setValue={setValue}
                        errors={formState.errors}
                        countryInputValue={countryInputValue}
                        setCountryInputValue={setCountryInputValue}
                        countries={countries}
                        setCountries={setCountries}
                        selectedCountry={selectedCountry}
                        showCountriesList={showCountriesList}
                        setShowCountriesList={setShowCountriesList}
                        setSelectedCountry={setSelectedCountry}
                        countriesStateList={countriesStateList}
                        setCountriesStateList={setCountriesStateList}
                        selectedCountryState={selectedCountryState}
                        setSelectedCountryState={setSelectedCountryState}
                        countryOfResidenceLabelText={
                          countryOfResidenceLabelText
                        }
                        countryStateLabelText={countryStateLabelText}
                      />
                    );
                  }

                  if (field.propertyName === "nationalityOf") {
                    return (
                      <CountryStateSection
                        key={`${field.labelText}${index}`}
                        isNationalityVariant={true}
                        name={"nationalityOf"}
                        programme={programme}
                        register={register}
                        control={control}
                        setValue={setValue}
                        errors={formState.errors}
                        countryInputValue={nationalityInputValue}
                        setCountryInputValue={setNationalityInputValue}
                        countries={nationalityCountries}
                        setCountries={setNationalityCountries}
                        selectedCountry={selectedNationalCountry}
                        setSelectedCountry={setSelectedNationalCountry}
                        showCountriesList={showNationalCountriesList}
                        setShowCountriesList={setShowNationalCountriesList}
                        countryOfResidenceLabelText={nationalityOfLabelText}
                      />
                    );
                  }

                  if (field.propertyName === "programmeSection") {
                    return (
                      <ProgrammesListSection
                        key={`${field.labelText}${index}`}
                        theme={theme}
                        register={register}
                        programme={programme}
                        programmesListData={programmesListData}
                        programmesHeadingText={programmesHeadingText}
                        programmesHintText={programmesHintText}
                        programmesSelectedTypes={programmesSelectedTypes}
                        setProgrammesSelectedTypes={setProgrammesSelectedTypes}
                        formState={formState}
                        name="selectedProgrammesList"
                      />
                    );
                  }

                  if (field.propertyName === "hrInformation") {
                    return (
                      <Checkbox
                        key={`${field.labelText}${index}`}
                        register={register}
                        theme={theme}
                        value={hrProfessionalText}
                        label={hrProfessionalText}
                        isChecked={isHrProfessional}
                        setIsChecked={setIsHrProfessional}
                      />
                    );
                  }

                  if (field.propertyName === "countryNumber") {
                    return (
                      <CountryNumber
                        key={`${field.labelText}${index}`}
                        dialCodeLabelText={countryCodeLabelText}
                        telephoneInputPlaceholderText={
                          telephoneNumberPlaceholderText
                        }
                        telephoneNumberHintText={telephoneNumberHintText}
                        optionsData={countryCodesData}
                        selectedDialCode={selectedDialCode}
                        setSelectedDialCode={setSelectedDialCode}
                        numberValue={numberValue}
                        setNumberValue={setNumberValue}
                        register={register}
                        formState={formState}
                        watch={watch}
                        errors={formState.errors}
                      />
                    );
                  }

                  if (
                    (field.propertyName === "password" ||
                      field.propertyName === "confirmPassword") &&
                    loggedInUser
                  ) {
                    return null;
                  }

                  if (field.propertyName === "institution") {
                    return (
                      <InstitutionSection
                        key={`${field.labelText}${index}`}
                        placeholder={field.labelText}
                        name={field.propertyName}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        errors={formState.errors}
                        value={watch(field.propertyName)}
                        getOptions={() => {}}
                        options={field?.options}
                        validation={field.validation}
                        watch={watch}
                        selectDescription="You must hold a management masters degree from an institution accredited by EQUIS/AACSB or from a University of London institution"
                        inputDescription="If you don’t hold a degree from any of the above Institutions, please add the Institution below:"
                      />
                    );
                  }

                  return (
                    <>
                      {loggedInUser &&
                      permanentFields.includes(field.propertyName) ? (
                        <FieldJSX
                          theme={theme}
                          key={`${field?.labelText}-${index}`}
                          labelText={field?.labelText}
                          dataText={field?.dataText}
                        />
                      ) : (
                        <FormField
                          formType={field?.formType}
                          watch={watch}
                          validation={field?.validation}
                          type={field?.type || "text"}
                          register={register}
                          property={field?.dataText}
                          selectedIdValues={selectedIdValues}
                          setSelectedIdValues={setSelectedIdValues}
                          key={`${field.labelText}${index}`}
                          selectValue={field?.selectValue}
                          setSelectValue={field?.setValue}
                          hintText={field?.hintText}
                          placeholder={field.labelText}
                          options={field.options}
                          name={field?.propertyName}
                          errors={formState.errors}
                          disabled={field?.disabled}
                        />
                      )}
                    </>
                  );
                })}
              </section>

              {!canContactByEmail && (
                <FormPrivacySection
                  register={register}
                  policyType={programme === "EMBAG" ? "embag" : "regular"}
                  formState={formState}
                  chosenEmailSubscription={loggedInUser && loggedInStayInformed}
                  embagPrivacyInformRadioYes={embagPrivacyInformRadioYes}
                  embagPrivacyInformRadioNo={embagPrivacyInformRadioNo}
                />
              )}

              <div className="btn-and-text-wrapper">
                <SubmitButton
                  loading={loading}
                  id="submitButton"
                  text="Submit"
                />
              </div>
            </>
          </div>
        </form>
      </section>
    );
  };

  return (
    <div className={clsx(theme["edit-details"], "wrapper component-wrap")}>
      <Grid row>
        <Grid column sm={12} md={6}>
          <div className={clsx(theme.component, "component", theme.form)}>
            <h1 className={styles.formTitle}>{formTitleText}</h1>
            {loggedInUser && <h2>{registerInterestLoggedInTitleText}</h2>}
            {error && (
              <FormErrorMessage
                failedRichText={failedMessage?.fields?.content}
                failedTinyMceRichText={failedMessage?.fields?.contentTinyMce}
              />
            )}
            {handledSubmitFormErrors &&
              handledSubmitFormErrors.map((error, index) => (
                <FormErrorMessage
                  key={index}
                  text={error}
                  userEmail={submittedUserEmail}
                />
              ))}
          </div>

          {renderFormSection()}
        </Grid>
      </Grid>
    </div>
  );
};

export default themr("RegisterInterestForm", styles)(RegisterInterestForm);
