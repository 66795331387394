import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import clsx from "clsx";

export interface RichTextFullWidthProps {
  content: any;
  contentTinyMce?: any;
  addBorder: boolean;
  capitaliseFirstLetter?: boolean;
}

const RichTextFullWidth = (props: RichTextFullWidthProps) => {
  const { content, addBorder, capitaliseFirstLetter, contentTinyMce } = props;

  return (
    <div className="wrapper">
      <div
        className={clsx(
          "component",
          "rich-text",
          capitaliseFirstLetter && "drop-caps",
          "text-placeholder",
          {
            border: addBorder,
          }
        )}
      >
        <RichTextDisplay richText={content} tinyMceRichText={contentTinyMce} />
      </div>
    </div>
  );
};

export default RichTextFullWidth;
